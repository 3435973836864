import React, { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <div className="terms-and-conditions container mt-5 mb-5">
      <h1 className="text-center">Terms and Conditions</h1>
      <p>Last updated Oct 23, 2024</p>

      <h4>1. AGREEMENT TO TERMS</h4>
      <p>
        These Terms of Use constitute a legally binding agreement made between
        you, whether personally or on behalf of an entity (“you”) and
        Infiboutique , doing business as Infiboutique (“Infiboutique“, “we”,
        “us”, or “our”), concerning your access to and use of the
        http://infiboutique.in website as well as any other media form, media
        channel, mobile website or mobile application related, linked, or
        otherwise connected thereto (collectively, the “Site”). You agree that
        by accessing the Site, you have read, understood, and agreed to be bound
        by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE
        TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND
        YOU MUST DISCONTINUE USE IMMEDIATELY.
      </p>
      <p>
        Supplemental terms and conditions or documents that may be posted on the
        Site from time to time are hereby expressly incorporated herein by
        reference. We reserve the right, in our sole discretion, to make changes
        or modifications to these Terms of Use at any time and for any reason.
        We will alert you about any changes by updating the “Last updated” date
        of these Terms of Use, and you waive any right to receive specific
        notice of each such change. Please ensure that you check the applicable
        Terms every time you use our Site so that you understand which Terms
        apply. You will be subject to, and will be deemed to have been made
        aware of and to have accepted, the changes in any revised Terms of Use
        by your continued use of the Site after the date such revised Terms of
        Use are posted.
      </p>
      <p>
        The information provided on the Site is not intended for distribution to
        or use by any person or entity in any jurisdiction or country where such
        distribution or use would be contrary to law or regulation or which
        would subject us to any registration requirement within such
        jurisdiction or country. Accordingly, those persons who choose to access
        the Site from other locations do so on their own initiative and are
        solely responsible for compliance with local laws, if and to the extent
        local laws are applicable.
      </p>
      <p>
        The Site is not tailored to comply with industry-specific regulations
        (Health Insurance Portability and Accountability Act (HIPAA), Federal
        Information Security Management Act (FISMA), etc.), so if your
        interactions would be subjected to such laws, you may not use this Site.
        You may not use the Site in a way that would violate the
        Gramm-Leach-Bliley Act (GLBA).
      </p>
      <p>
        The Site is intended for users who are at least 18 years old. Persons
        under the age of 18 are not permitted to use or register for the Site.
      </p>

      <h4>2. INTELLECTUAL PROPERTY RIGHTS</h4>
      <p>
        Unless otherwise indicated, the Site is our proprietary property and all
        source code, databases, functionality, software, website designs, audio,
        video, text, photographs, and graphics on the Site (collectively, the
        “Content”) and the trademarks, service marks, and logos contained
        therein (the “Marks”) are owned or controlled by us or licensed to us,
        and are protected by copyright and trademark laws and various other
        intellectual property rights and unfair competition laws of the United
        States, international copyright laws, and international conventions.
      </p>
      <p>
        The Content and the Marks are provided on the Site “AS IS” for your
        information and personal use only. Except as expressly provided in these
        Terms of Use, no part of the Site and no Content or Marks may be copied,
        reproduced, aggregated, republished, uploaded, posted, publicly
        displayed, encoded, translated, transmitted, distributed, sold,
        licensed, or otherwise exploited for any commercial purpose whatsoever,
        without our express prior written permission.
      </p>
      <p>
        Provided that you are eligible to use the Site, you are granted a
        limited license to access and use the Site and to download or print a
        copy of any portion of the Content to which you have properly gained
        access solely for your personal, non-commercial use. We reserve all
        rights not expressly granted to you in and to the Site, the Content and
        the Marks.
      </p>

      <h4>3. USER REPRESENTATIONS</h4>
      <p>
        By using the Site, you represent and warrant that: (1) all registration
        information you submit will be true, accurate, current, and complete;
        (2) you will maintain the accuracy of such information and promptly
        update such registration information as necessary; (3) you have the
        legal capacity and you agree to comply with these Terms of Use; (4) you
        are not a minor in the jurisdiction in which you reside; (5) you will
        not access the Site through automated or non-human means, whether
        through a bot, script or otherwise; (6) you will not use the Site for
        any illegal or unauthorized purpose; and (7) your use of the Site will
        not violate any applicable law or regulation.
      </p>
      <p>
        If you provide any information that is untrue, inaccurate, not current,
        or incomplete, we have the right to suspend or terminate your account
        and refuse any and all current or future use of the Site (or any portion
        thereof).
      </p>

      <h4>4. USER REGISTRATION</h4>
      <p>
        You may be required to register with the Site. You agree to keep your
        password confidential and will be responsible for all use of your
        account and password. We reserve the right to remove, reclaim, or change
        a username you select if we determine, in our sole discretion, that such
        username is inappropriate, obscene, or otherwise objectionable.
      </p>

      <h4>5. PRODUCTS</h4>
      <p>
        We make every effort to display as accurately as possible the colors,
        features, specifications, and details of the products available on the
        Site. However, we do not guarantee that the colors, features,
        specifications, and details of the products will be accurate, complete,
        reliable, current, or free of other errors, and your electronic display
        may not accurately reflect the actual colors and details of the
        products. All products are subject to availability, and we cannot
        guarantee that items will be in stock. We reserve the right to
        discontinue any products at any time for any reason. Prices for all
        products are subject to change.
      </p>
      <h4>6. PURCHASES AND PAYMENT</h4>
      <p>We accept the following forms of payment:</p>
      <ul>
        <li>Visa</li>
        <li>Mastercard</li>
        <li>American Express</li>
        <li>PayPal</li>
        <li>UPI</li>
        <li>Debit Card</li>
        <li>Credit Card</li>
      </ul>

      <p>
        You agree to provide current, complete, and accurate purchase and
        account information for all purchases made via the Site. You further
        agree to promptly update account and payment information, including
        email address, payment method, and payment card expiration date, so that
        we can complete your transactions and contact you as needed. Sales tax
        will be added to the price of purchases as deemed required by us. We may
        change prices at any time. All payments shall be in rupee.
      </p>

      <p>
        You agree to pay all charges at the prices then in effect for your
        purchases and any applicable shipping fees, and you authorize us to
        charge your chosen payment provider for any such amounts upon placing
        your order. We reserve the right to correct any errors or mistakes in
        pricing, even if we have already requested or received payment.
      </p>

      <p>
        We reserve the right to refuse any order placed through the Site. We
        may, in our sole discretion, limit or cancel quantities purchased per
        person, per household, or per order. These restrictions may include
        orders placed by or under the same customer account, the same payment
        method, and/or orders that use the same billing or shipping address. We
        reserve the right to limit or prohibit orders that, in our sole
        judgment, appear to be placed by dealers, resellers, or distributors.
      </p>

      <h4>7. RETURN POLICY</h4>
      <p>
        Please review our Return Policy posted on the Site prior to making any
        purchases.
      </p>

      <h4>8. PROHIBITED ACTIVITIES</h4>
      <p>
        You may not access or use the Site for any purpose other than that for
        which we make the Site available. The Site may not be used in connection
        with any commercial endeavors except those that are specifically
        endorsed or approved by us.
      </p>
      <p>As a user of the Site, you agree not to:</p>
      <ul>
        <li>
          Systematically retrieve data or other content from the Site to create
          or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </li>
        <li>
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </li>
        <li>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Site.
        </li>
        <li>
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Site.
        </li>
        <li>
          Use any information obtained from the Site in order to harass, abuse,
          or harm another person.
        </li>
        <li>
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </li>
        <li>
          Use the Site in a manner inconsistent with any applicable laws or
          regulations.
        </li>
        <li>Engage in unauthorized framing of or linking to the Site.</li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material that interferes with any party’s
          uninterrupted use of the Site.
        </li>
        <li>
          Engage in any automated use of the system, such as using scripts to
          send comments or messages.
        </li>
        <li>
          Delete the copyright or other proprietary rights notice from any
          Content.
        </li>
        <li>
          Attempt to impersonate another user or person or use the username of
          another user.
        </li>
        <li>
          Upload or transmit any material that acts as a passive or active
          information collection mechanism.
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Site or the
          networks or services connected to the Site.
        </li>
        <li>
          Harass, annoy, intimidate, or threaten any of our employees or agents.
        </li>
        <li>
          Attempt to bypass any measures of the Site designed to prevent or
          restrict access to the Site.
        </li>
        <li>
          Copy or adapt the Site’s software, including but not limited to Flash,
          PHP, HTML, JavaScript, or other code.
        </li>
        <li>
          Use, launch, develop, or distribute any automated system that accesses
          the Site.
        </li>
        <li>
          Use a buying agent or purchasing agent to make purchases on the Site.
        </li>
        <li>
          Make any unauthorized use of the Site, including collecting usernames
          and/or email addresses of users for unsolicited email.
        </li>
        <li>
          Use the Site as part of any effort to compete with us or use the Site
          and/or the Content for any revenue-generating endeavor.
        </li>
        <li>Use the Site to advertise or offer to sell goods and services.</li>
        <li>Sell or otherwise transfer your profile.</li>
      </ul>

      <h4>9. USER GENERATED CONTRIBUTIONS</h4>
      <p>
        The Site may invite you to chat, contribute to, or participate in blogs,
        message boards, online forums, and other functionality, and may provide
        you with the opportunity to create, submit, post, display, transmit,
        perform, publish, distribute, or broadcast content and materials to us
        or on the Site, including but not limited to text, writings, video,
        audio, photographs, graphics, comments, suggestions, or personal
        information or other material (collectively, “Contributions”).
        Contributions may be viewable by other users of the Site and the
        Marketplace Offerings and through third-party websites. As such, any
        Contributions you transmit may be treated as non-confidential and
        non-proprietary.
      </p>
      <p>
        When you create or make available any Contributions, you thereby
        represent and warrant that:
      </p>
      <ul>
        <li>
          The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights of
          any third party.
        </li>
        <li>
          You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize us
          to use your Contributions.
        </li>
        <li>
          You have the written consent of each identifiable individual in your
          Contributions to use their name or likeness.
        </li>
        <li>Your Contributions are not false, inaccurate, or misleading.</li>
        <li>
          Your Contributions are not unsolicited or unauthorized advertising or
          other forms of solicitation.
        </li>
        <li>
          Your Contributions are not obscene, lewd, or otherwise objectionable.
        </li>
        <li>
          Your Contributions do not promote violence or harass any person or
          class of people.
        </li>
        <li>
          Your Contributions do not violate any applicable law or regulation.
        </li>
        <li>
          Your Contributions do not violate the privacy rights of any third
          party.
        </li>
        <li>
          Your Contributions do not include offensive comments related to race,
          national origin, gender, or other characteristics.
        </li>
        <li>Your Contributions do not otherwise violate these Terms of Use.</li>
      </ul>

      <h4>10. CONTRIBUTION LICENSE</h4>
      <p>
        By posting your Contributions to any part of the Site, you automatically
        grant us an unrestricted, irrevocable, perpetual, non-exclusive,
        transferable, royalty-free, fully-paid, worldwide right to use, copy,
        reproduce, disclose, sell, publish, broadcast, and distribute such
        Contributions for any purpose.
      </p>
      <p>
        This license will apply to any form, media, or technology now known or
        hereafter developed, and includes our use of your name, company name,
        and trademarks. You waive all moral rights in your Contributions.
      </p>
      <p>
        We do not assert any ownership over your Contributions. You retain full
        ownership of your Contributions and any intellectual property rights
        associated with them. We are not liable for any statements or
        representations in your Contributions.
      </p>
      <p>
        We have the right to edit, redact, or otherwise change any Contributions
        and to pre-screen or delete any Contributions at any time and for any
        reason, without notice.
      </p>
      <h4>11. GUIDELINES FOR REVIEWS</h4>
      <p>
        We may provide you areas on the Site to leave reviews or ratings. When
        posting a review, you must comply with the following criteria:
      </p>
      <ul>
        <li>
          You should have firsthand experience with the person/entity being
          reviewed.
        </li>
        <li>
          Your reviews should not contain offensive profanity or abusive,
          racist, offensive, or hate language.
        </li>
        <li>
          Your reviews should not contain discriminatory references based on
          religion, race, gender, national origin, age, marital status, sexual
          orientation, or disability.
        </li>
        <li>Your reviews should not contain references to illegal activity.</li>
        <li>
          You should not be affiliated with competitors if posting negative
          reviews.
        </li>
        <li>
          You should not make any conclusions as to the legality of conduct.
        </li>
        <li>You may not post any false or misleading statements.</li>
        <li>
          You may not organize a campaign encouraging others to post reviews,
          whether positive or negative.
        </li>
      </ul>
      <p>
        We may accept, reject, or remove reviews in our sole discretion. We have
        absolutely no obligation to screen reviews or to delete reviews, even if
        anyone considers reviews objectionable or inaccurate. Reviews are not
        endorsed by us, and do not necessarily represent our opinions or the
        views of any of our affiliates or partners. We do not assume liability
        for any review or for any claims, liabilities, or losses resulting from
        any review. By posting a review, you hereby grant to us a perpetual,
        non-exclusive, worldwide, royalty-free, fully-paid, assignable, and
        sublicensable right and license to reproduce, modify, translate,
        transmit by any means, display, perform, and/or distribute all content
        relating to review.
      </p>

      <h4>12. SOCIAL MEDIA</h4>
      <p>
        As part of the functionality of the Site, you may link your account with
        online accounts you have with third-party service providers (each such
        account, a “Third-Party Account”). You represent and warrant that you
        are entitled to disclose your Third-Party Account login information to
        us and/or grant us access to your Third-Party Account, without breaching
        any terms and conditions that govern your use of the applicable
        Third-Party Account.
      </p>
      <p>
        By granting us access to any Third-Party Accounts, you understand that
        we may access, make available, and store any content that you have
        provided to and stored in your Third-Party Account. You acknowledge that
        if a Third-Party Account or associated service becomes unavailable, then
        Social Network Content may no longer be available on and through the
        Site. You can disable the connection between your account and your
        Third-Party Accounts at any time.
      </p>
      <p>
        Please note that your relationship with the third-party service
        providers associated with your Third-Party Accounts is governed solely
        by your agreement(s) with such third-party service providers.
      </p>

      <h4>13. SUBMISSIONS</h4>
      <p>
        You acknowledge that any questions, comments, suggestions, ideas,
        feedback, or other information regarding the Site or the Marketplace
        Offerings (“Submissions”) provided by you to us are non-confidential and
        shall become our sole property. We shall own exclusive rights, including
        all intellectual property rights, and shall be entitled to the
        unrestricted use and dissemination of these Submissions for any lawful
        purpose, commercial or otherwise.
      </p>
      <p>
        You hereby waive all moral rights to any such Submissions, and you
        warrant that any such Submissions are original with you or that you have
        the right to submit such Submissions.
      </p>

      <h4>14. THIRD-PARTY WEBSITES AND CONTENT</h4>
      <p>
        The Site may contain links to other websites (“Third-Party Websites”)
        and third-party content. Such Third-Party Websites and Third-Party
        Content are not investigated or monitored by us. Inclusion of any
        Third-Party Websites or Third-Party Content does not imply approval or
        endorsement by us.
      </p>
      <p>
        If you decide to leave the Site and access Third-Party Websites, you do
        so at your own risk. We do not endorse the products or services offered
        on Third-Party Websites, and you shall hold us harmless from any harm
        caused by your purchase of such products or services.
      </p>

      <h4>15. ADVERTISERS</h4>
      <p>
        We allow advertisers to display their advertisements on certain areas of
        the Site. If you are an advertiser, you shall take full responsibility
        for any advertisements you place on the Site. We have no other
        relationship with advertisers.
      </p>
      <p>
        Further, as an advertiser, you warrant that you possess all rights and
        authority to place advertisements on the Site, including intellectual
        property rights, publicity rights, and contractual rights.
      </p>
      <h4>16. SITE MANAGEMENT</h4>
      <p>
        We reserve the right, but not the obligation, to: (1) monitor the Site
        for violations of these Terms of Use; (2) take appropriate legal action
        against anyone who, in our sole discretion, violates the law or these
        Terms of Use, including without limitation, reporting such user to law
        enforcement authorities; (3) in our sole discretion and without
        limitation, refuse, restrict access to, limit the availability of, or
        disable (to the extent technologically feasible) any of your
        Contributions or any portion thereof; (4) in our sole discretion and
        without limitation, notice, or liability, to remove from the Site or
        otherwise disable all files and content that are excessive in size or
        are in any way burdensome to our systems; and (5) otherwise manage the
        Site in a manner designed to protect our rights and property and to
        facilitate the proper functioning of the Site and the Marketplace
        Offerings.
      </p>

      <h4>17. PRIVACY POLICY</h4>
      <p>
        We care about data privacy and security. By using the Site or the
        Marketplace Offerings, you agree to be bound by our Privacy Policy
        posted on the Site, which is incorporated into these Terms of Use.
        Please be advised the Site and the Marketplace Offerings are hosted in
        India. If you access the Site or the Marketplace Offerings from any
        other region of the world with laws or other requirements governing
        personal data collection, use, or disclosure that differ from applicable
        laws in India, then through your continued use of the Site, you are
        transferring your data to India, and you expressly consent to have your
        data transferred to and processed in India.
      </p>

      <h4>18. TERM AND TERMINATION</h4>
      <p>
        These Terms of Use shall remain in full force and effect while you use
        the Site. We reserve the right to, in our sole discretion and without
        notice or liability, deny access to and use of the Site and the
        Marketplace Offerings (including blocking certain IP addresses), to any
        person for any reason or for no reason. We may terminate your use or
        participation in the Site at any time, without warning, in our sole
        discretion.
      </p>
      <p>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party. In addition to
        terminating or suspending your account, we reserve the right to take
        appropriate legal action.
      </p>

      <h4>19. MODIFICATIONS AND INTERRUPTIONS</h4>
      <p>
        We reserve the right to change, modify, or remove the contents of the
        Site at any time or for any reason at our sole discretion without
        notice. We also reserve the right to modify or discontinue all or part
        of the Marketplace Offerings without notice at any time. We will not be
        liable for any modification, price change, suspension, or discontinuance
        of the Site or the Marketplace Offerings.
      </p>
      <p>
        We cannot guarantee the Site will be available at all times. We reserve
        the right to change, revise, update, suspend, discontinue, or otherwise
        modify the Site at any time or for any reason without notice to you. You
        agree that we have no liability for any loss, damage, or inconvenience
        caused by your inability to access or use the Site during any downtime.
      </p>

      <h4>20. GOVERNING LAW</h4>
      <p>
        These terms shall be governed by and defined following the laws of
        India. You irrevocably consent that the courts of India shall have
        exclusive jurisdiction to resolve any dispute related to these terms.
      </p>

      <h4>21. DISPUTE RESOLUTION</h4>
      <p>
        You agree to irrevocably submit all disputes related to these Terms or
        the relationship established by this Agreement to the jurisdiction of
        the Indian courts. We maintain the right to bring proceedings in the
        courts of the country where you reside or the state of your principal
        place of business.
      </p>

      <h4>22. CORRECTIONS</h4>
      <p>
        There may be information on the Site that contains typographical errors,
        inaccuracies, or omissions related to the Marketplace Offerings. We
        reserve the right to correct any errors, inaccuracies, or omissions and
        to change or update the information on the Site at any time, without
        prior notice.
      </p>

      <h4>23. DISCLAIMER</h4>
      <p>
        THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
        YOUR USE OF THE SITE SERVICES WILL BE AT YOUR SOLE RISK. WE DISCLAIM ALL
        WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE, INCLUDING
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
        ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT AND ASSUME NO LIABILITY
        FOR ANY ERRORS, PERSONAL INJURY, PROPERTY DAMAGE, OR INTERRUPTION OF
        SERVICE.
      </p>
      <p>
        WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
        PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
        SITE.
      </p>

      <h4>24. LIMITATIONS OF LIABILITY</h4>
      <p>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE FOR
        ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
        PUNITIVE DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR LIABILITY TO YOU WILL BE
        LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US.
      </p>

      <h4>25. INDEMNIFICATION</h4>
      <p>
        You agree to defend, indemnify, and hold us harmless from any loss,
        damage, liability, claim, or demand, including reasonable attorneys’
        fees, made by any third party due to or arising out of: (1) your
        Contributions; (2) use of the Site; (3) breach of these Terms of Use;
        (4) any breach of your representations and warranties; (5) your
        violation of the rights of a third party; or (6) any harmful act toward
        any other user. We reserve the right to assume the exclusive defense of
        any matter for which you are required to indemnify us, and you agree to
        cooperate with our defense of such claims.
      </p>
      <h4>26. USER DATA</h4>
      <p>
        We will maintain certain data that you transmit to the Site for the
        purpose of managing the performance of the Site, as well as data
        relating to your use of the Site. Although we perform regular routine
        backups of data, you are solely responsible for all data that you
        transmit or that relates to any activity you have undertaken using the
        Site. You agree that we shall have no liability to you for any loss or
        corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.
      </p>

      <h4>27. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h4>
      <p>
        Visiting the Site, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Site, satisfy any legal requirement that such communication be in
        writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE SITE. You hereby waive any rights or requirements under
        any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.
      </p>

      <h4>28. CALIFORNIA USERS AND RESIDENTS</h4>
      <p>
        If any complaint with us is not satisfactorily resolved, you can contact
        the Complaint Assistance Unit of the Division of Consumer Services of
        the California Department of Consumer Affairs in writing at 1625 North
        Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
        at (800) 952-5210 or (916) 445-1254.
      </p>

      <h4>29. MISCELLANEOUS</h4>
      <p>
        These Terms of Use and any policies or operating rules posted by us on
        the Site or in respect to the Site constitute the entire agreement and
        understanding between you and us. Our failure to exercise or enforce any
        right or provision of these Terms of Use shall not operate as a waiver
        of such right or provision. These Terms of Use operate to the fullest
        extent permissible by law. We may assign any or all of our rights and
        obligations to others at any time. We shall not be responsible or liable
        for any loss, damage, delay, or failure to act caused by any cause
        beyond our reasonable control. If any provision or part of a provision
        of these Terms of Use is determined to be unlawful, void, or
        unenforceable, that provision or part of the provision is deemed
        severable from these Terms of Use and does not affect the validity and
        enforceability of any remaining provisions. There is no joint venture,
        partnership, employment, or agency relationship created between you and
        us as a result of these Terms of Use or use of the Site. You agree that
        these Terms of Use will not be construed against us by virtue of having
        drafted them. You hereby waive any and all defenses you may have based
        on the electronic form of these Terms of Use and the lack of signing by
        the parties hereto to execute these Terms of Use.
      </p>

      <h4>30. CONTACT US</h4>
      <p>
        In order to resolve a complaint regarding the Site or to receive further
        information regarding use of the Site, please contact us at:
      </p>
      <p>
        Infiboutique
        <br />
        29, Pookara Muslim Street,
        <br />
        Vandayar Colony,
        <br />
        Thanjavur, Tamil Nadu.
        <br />
        Phone: 9952095586
        <br />
        Email: priyamk1371@gmail.com
      </p>
    </div>
  );
};

export default TermsAndConditions;
