import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import { fetchcategoryproductslist, createFavorite } from "./Apiservice";
import "bootstrap/dist/css/bootstrap.min.css";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import Swal from "sweetalert2";
import { FaHeart, FaRegHeart } from "react-icons/fa";

const Product = () => {
  const { categoryid } = useParams();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [favorites, setFavorites] = useState([]);
  const productsPerPage = 12;
  const offset = currentPage * productsPerPage;

  const currentProducts = products.slice(offset, offset + productsPerPage);
  useEffect(() => {
    // Check if a page number exists in localStorage
    const savedPage = localStorage.getItem("currentPage");

    if (savedPage !== null) {
      setCurrentPage(parseInt(savedPage)); // Set the page to the saved page number
    } else {
      setCurrentPage(0); // Default to the first page if no saved page exists
    }

    window.scrollTo(0, 0); // Scroll to the top on initial page load
  }, []);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await fetchcategoryproductslist(categoryid);
        setProducts(data.reverse()); // Reverse only once in useEffect
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, [categoryid]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    window.scrollTo(0, 0);

    // Save the current page to localStorage
    localStorage.setItem("currentPage", selectedPage);
  };
  const toggleFavorite = (productId) => {
    if (favorites.includes(productId)) {
      setFavorites(favorites.filter((id) => id !== productId));
    } else {
      setFavorites([...favorites, productId]);
    }
  };

  const handleAddToFavorites = async (productId) => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        await createFavorite(userId, productId);
        Swal.fire("Success", "Product added to favorites!", "success");
      } catch (error) {
        Swal.fire("Error", "Failed to add to favorites.", "error");
      }
    } else {
      Swal.fire("Error", "Please log in to add favorites.", "error");
    }
  };

  const openModal = (product) => {
    setSelectedProduct(product);
    setCurrentImageIndex(0);
    const modal = new window.bootstrap.Modal(
      document.getElementById("modal_box")
    );
    modal.show();
  };

  const closeModal = () => {
    const modalElement = document.getElementById("modal_box");
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    if (modalInstance) modalInstance.hide();
    setSelectedProduct(null);
  };

  const handleNextImage = () => {
    if (selectedProduct) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex < selectedProduct.images.length - 1 ? prevIndex + 1 : 0
      );
    }
  };

  const handlePrevImage = () => {
    if (selectedProduct) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : selectedProduct.images.length - 1
      );
    }
  };

  return (
    <div>
      <div className="product-card-wrapper container">
        <h2 className="text-center my-5">OUR PRODUCTS</h2>

        <div className="grid-container">
          {currentProducts.map((product) => {
            const originalPrice = parseFloat(product.price);
            const discountedPrice =
              originalPrice -
              (originalPrice * product.discount_percentage) / 100;

            return (
              <div key={product.id} className="grid-item">
                <div className="product-thumb">
                  <Link to={`/Productdetail/${product.id}`}>
                    <img
                      className="primary-img"
                      src={product.images[0]?.image}
                      alt={product.name}
                    />
                    {product.images[1] && (
                      <img
                        className="secondary-img"
                        src={product.images[1]?.image}
                        alt={`${product.name} hover`}
                      />
                    )}
                  </Link>
                  {product.discount_percentage > 0 && (
                    <div className="product-label">
                      <span>-{product.discount_percentage}%</span>
                    </div>
                  )}
                  {product.sold_out && (
                    <div className="product-label sold-out">
                      <span>Sold Out</span>
                    </div>
                  )}
                  <div className="product-action">
                    <ul>
                      <li
                        className="wishlist"
                        onClick={() => {
                          handleAddToFavorites(product.id);
                          toggleFavorite(product.id);
                        }}
                      >
                        <Link to="#" title="Wishlist">
                          {favorites.includes(product.id) ? (
                            <FaHeart className="wishlist-icon active" />
                          ) : (
                            <FaRegHeart className="wishlist-icon" />
                          )}
                        </Link>
                      </li>
                      <li className="quick-view">
                        <Link
                          to="#"
                          title="Quick View"
                          onClick={() => openModal(product)}
                        >
                          <i className="icon-size-fullscreen icons"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-content">
                  <h4 className="product-name">
                    <Link to={`/Productdetail/${product.id}`}>
                      {product.name}
                    </Link>
                  </h4>
                  <div className="price-box">
                    <span className="current-price">
                      ₹
                      {discountedPrice.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                      })}
                      <span className="inr">INR</span>
                    </span>
                    {product.discount_percentage > 0 && (
                      <span className="old-price">
                        ₹
                        {originalPrice.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                        })}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="d-flex justify-content-center my-5">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={Math.ceil(products.length / productsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />{" "}
        </div>
      </div>

      <div
        className="modal fade"
        id="modal_box"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{selectedProduct?.name}</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {selectedProduct && (
                <div className="row">
                  <div className="col-md-6 text-center position-relative">
                    <img
                      src={selectedProduct.images[currentImageIndex].image}
                      alt={selectedProduct.name}
                      className="img-fluid"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        width: "100%",
                        transform: "translateY(-50%)",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="btn btn-secondary"
                        onClick={handlePrevImage}
                        style={{
                          zIndex: 1,
                          background: "rgba(0,0,0,0.5)", // Semi-transparent background for better visibility
                        }}
                      >
                        <HiArrowNarrowLeft />
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={handleNextImage}
                        style={{
                          zIndex: 1,
                          background: "rgba(0,0,0,0.5)", // Semi-transparent background for better visibility
                        }}
                      >
                        <HiArrowNarrowRight />
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h5>{selectedProduct?.name}</h5>
                    <h5>Price: {selectedProduct.price}</h5>
                    <p>{selectedProduct.description}</p>
                    <p>Fabric: {selectedProduct.fabric}</p>
                    <p>Style: {selectedProduct.style}</p>
                    <p>Model Height: {selectedProduct.model_height}</p>
                    <p>
                      Model Wearing Size: {selectedProduct.model_wearing_size}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
