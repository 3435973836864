import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Pages/Home";
import Product from "./Components/Pages/Product";
import "./App.css";
import Footer from "./Components/Mainpages/Footer";
import Header from "./Components/Mainpages/Header";
import Productdetail from "./Components/Pages/Productdetail";
import Cart from "./Components/Pages/Cart";
import Payment from "./Components/Pages/Payment";
import Notification from "./Components/Pages/Notification";
import Minicart from "./Components/Pages/Minicart";
import AuthForm from "./Components/Pages/AuthForm";
import Contact from "./Components/Pages/Contact";
import Favourite from "./Components/Pages/Favourite";
import SearchModal from "./Components/Pages/SearchModal";
import Loading from "./Components/Pages/Loading";
import Trackorder from "./Components/Pages/Trackorder";
import Privacypolicy from "./Components/Pages/Privacypolicy";
import Refundpolicy from "./Components/Pages/Refundpolicy";
import Shippingpolicy from "./Components/Pages/Shippingpolicy";
import Returnpolicy from "./Components/Pages/Returnpolicy";
import TermsAndConditions from "./Components/Pages/Termsandcondition";
import Latestcollection from "./Components/Pages/Latestcollection";
import Bestseller from "./Components/Pages/Bestseller";
import Featured from "./Components/Pages/Featured";
import { useEffect } from "react";
// Ensure this is imported

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Bestseller" element={<Bestseller />} />
        <Route path="/Featured" element={<Featured />} />
        <Route path="/Latestcollection" element={<Latestcollection />} />
        <Route path="/product/:categoryid" element={<Product />} />
        <Route path="/Productdetail/:id" element={<Productdetail />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/Minicart" element={<Minicart />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/Notification" element={<Notification />} />
        <Route path="/AuthForm" element={<AuthForm />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Favourite" element={<Favourite />} />
        <Route path="/Trackorder" element={<Trackorder />} />
        <Route path="/SearchModal" element={<SearchModal />} />
        <Route path="/Privacypolicy" element={<Privacypolicy />} />
        <Route path="/Refundpolicy" element={<Refundpolicy />} />
        <Route path="/Shippingpolicy" element={<Shippingpolicy />} />
        <Route path="/Returnpolicy" element={<Returnpolicy />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/Loading" element={<Loading />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
