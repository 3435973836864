// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import {
//   signupUser,
//   OtpUser,
//   loginUser,
//   requestPasswordReset,
//   confirmPasswordReset,
// } from "./Apiservice";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// import Swal from "sweetalert2";

// const AuthForm = () => {
//   const [isSignUp, setIsSignUp] = useState(false);
//   const [isOtpVerification, setIsOtpVerification] = useState(false);
//   const [isForgotPassword, setIsForgotPassword] = useState(false);
//   const [isSignedIn, setIsSignedIn] = useState(false);
//   const [isResetPassword, setIsResetPassword] = useState(false);

//   const handleOverlayClick = () => {
//     setIsSignUp(!isSignUp);
//     setIsOtpVerification(false);
//     setIsForgotPassword(false);
//     setIsSignedIn(false);
//     setIsResetPassword(false);
//   };

//   const handleForgotPasswordClick = (event) => {
//     event.preventDefault();
//     setIsForgotPassword(true);
//     setIsSignUp(false);
//     setIsOtpVerification(false);
//     setIsSignedIn(false);
//     setIsResetPassword(false);
//   };

//   const handleBackToSignIn = () => {
//     setIsForgotPassword(false);
//     setIsSignUp(false);
//     setIsOtpVerification(false);
//     setIsSignedIn(false);
//     setIsResetPassword(false);
//   };

//   const handleBackToSignUp = () => {
//     setIsForgotPassword(false);
//     setIsSignUp(true);
//     setIsOtpVerification(false);
//     setIsSignedIn(false);
//     setIsResetPassword(false);
//   };

//   // --------------------------SIGNUP----------------------
//   const [formData, setFormData] = useState({
//     username: "",
//     email: "",
//     mobile: "",
//     password: "",
//     confirm_password: "",
//   });
//   const [showPassword, setShowPassword] = useState(false);
//   const [shownewPassword, setShownewPassword] = useState(false);
//   const [shownewConfirmPassword, setShownewConfirmPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleTogglePassword = () => {
//     setShowPassword(!showPassword);
//     setShownewPassword(!shownewPassword);
//     setShownewConfirmPassword(!shownewConfirmPassword);
//   };

//   const handleToggleConfirmPassword = () => {
//     setShowConfirmPassword(!showConfirmPassword);
//     setShownewConfirmPassword(!shownewConfirmPassword);
//   };

//   const handleSignUpSUBMISSIONClick = async () => {
//     if (formData.password !== formData.confirm_password) {
//       Swal.fire({
//         title: "Error!",
//         text: "Passwords do not match",
//         icon: "error",
//         confirmButtonText: "Try Again",
//       });
//       return;
//     }

//     try {
//       console.log("Sign up formData:", formData); // Add this line to log form data
//       const response = await signupUser(formData);
//       Swal.fire({
//         title: "Please verifiy OTP!",
//         text: "You have successfully registered. Please verifiy OTP.",
//         icon: "success",
//         confirmButtonText: "OK",
//       });
//       console.log("Registration successful", response);
//       setIsSignUp(false);
//       setIsOtpVerification(true);
//       setFormData({
//         username: "",
//         email: "",
//         mobile: "",
//         password: "",
//         confirm_password: "",
//       });
//     } catch (error) {
//       if (error.response && error.response.status === 400) {
//         // Adjust the status code based on your API
//         Swal.fire({
//           title: "Error!",
//           text: "An account with this email or Phone no already exists.",
//           icon: "error",
//           confirmButtonText: "Try Again",
//         });
//       } else {
//         Swal.fire({
//           title: "Error!",
//           text: "Registration failed. Please try again.",
//           icon: "error",
//           confirmButtonText: "Try Again",
//         });
//       }
//       console.error("Registration failed", error.response);
//     }
//   };
// const [email, setEmail] = useState("");
// const [otp, setOtp] = useState("");
// const [errorMessage, setErrorMessage] = useState("");
// const handleOtpVerification = async () => {
//   try {
//     const response = await OtpUser({
//       email: email,
//       code: otp,
//     });
//     Swal.fire({
//       title: "OTP Verified successfully!",
//       text: "You have successfully OTP Verified. Please log in.",
//       icon: "success",
//       confirmButtonText: "OK",
//     });
//     console.log("OTP Verified successfully!", response);
//     setEmail("");
//     setOtp("");
//     setIsOtpVerification(false);

//     setIsSignedIn(false);
//   } catch (error) {
//     Swal.fire({
//       title: "Error!",
//       text: "OTP Verified failed. Please try again.",
//       icon: "error",
//       confirmButtonText: "Try Again",
//     });
//     console.error("Error verifying OTP", error);
//   }
// };

//   const [emaillogin, setEmaillogin] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState("");

//   const [emailforget, setEmailforget] = useState("");
//   const [message, setMessage] = useState("");

//   const handleSendResetLinkClick = async () => {
//     try {
//       const response = await requestPasswordReset(emailforget);
//       setMessage("Password reset OTP sent to your email.");
//       setIsResetPassword(true);
//       setIsForgotPassword(false);
//       Swal.fire({
//         title: "success!",
//         text: "Password reset OTP sent to your email. ",
//         icon: "success",
//         confirmButtonText: "OK",
//       });
//     } catch (error) {
//       setError("Failed to send OTP. Please check the email.");
//       Swal.fire({
//         title: "Error!",
//         text: "Failed to send OTP. Please try again.",
//         icon: "error",
//         confirmButtonText: "Try Again",
//       });
//     }
//   };

//   const [emailreset, setEmailreset] = useState("");
//   const [otpreset, setOtpreset] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");

// const handleResetPasswordSubmit = async (e) => {
//   e.preventDefault();
//   if (newPassword !== confirmPassword) {
//     setError("Passwords do not match.");
//     return;
//   }

//   try {
//     const userData = {
//       email: emailreset,
//       otp: otpreset,
//       new_password: newPassword,
//       confirm_password: confirmPassword,
//     };
//     const response = await confirmPasswordReset(userData);

//     Swal.fire({
//       title: "success!",
//       text: "Password reset successful. You can now log in ",
//       icon: "success",
//       confirmButtonText: "OK",
//     });
//     setEmailreset("");
//     setOtpreset("");
//     setNewPassword("");
//     setConfirmPassword("");
//     setIsSignedIn(false);
//   } catch (error) {
//     Swal.fire({
//       title: "Error!",
//       text: "Failed to reset password. Please check your details.",
//       icon: "error",
//       confirmButtonText: "Try Again",
//     });
//     console.error(
//       "Error details: ",
//       error.response ? error.response.data : error.message
//     );
//   }
// };

//   // Function to get the CSRF token from cookies
//   function getCookie(name) {
//     let cookieValue = null;
//     if (document.cookie && document.cookie !== "") {
//       const cookies = document.cookie.split(";");
//       for (let i = 0; i < cookies.length; i++) {
//         const cookie = cookies[i].trim();
//         if (cookie.substring(0, name.length + 1) === name + "=") {
//           cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//           break;
//         }
//       }
//     }
//     return cookieValue;
//   }

// const csrftoken = getCookie("csrftoken");

// const handleSignInClick = () => {
//   setError(null);

//   fetch("https://infiboutique.in/api/api/login/", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "X-CSRFToken": csrftoken,
//     },
//     body: JSON.stringify({
//       email: email,
//       password: password,
//     }),
//   })
//     .then((response) => {
//       if (response.ok) {
//         return response.json();
//       }
//       throw new Error("Login failed. Please check your credentials.");
//     })
//     .then((data) => {
//       console.log("Login successful:", data);

//       // Save user ID to local storage
//       localStorage.setItem("userId", data.user.user_id); // Assuming the user ID is stored under user.user_id
//       // Optionally save other user info if needed
//       localStorage.setItem("username", data.user.username); // Example for saving username

//       setEmail("");
//       setPassword("");
//       setIsSignedIn(true);
//       Swal.fire({
//         icon: "success",
//         title: "Login Successful",
//         text: "You are now logged in.",
//         confirmButtonText: "OK",
//       }).then((result) => {
//         if (result.isConfirmed) {
//           window.location.href = "/"; // Redirect to home page after clicking OK
//         }
//       });
//     })
//     .catch((error) => {
//       console.error("There was a problem with your fetch operation:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Login Failed",
//         text: "Please check your credentials and try again.",
//         confirmButtonText: "OK",
//       });
//     });
// };

//   return (
//     <div className="AuthForm-main">
//       <div
//         className={`AuthForm-main-AuthForm-container ${
//           isSignUp ? "AuthForm-main-right-panel-active" : ""
//         }`}
//       >
//         {!isOtpVerification &&
//         !isForgotPassword &&
//         !isSignedIn &&
//         !isResetPassword ? (
//           <>
//             {isSignUp && (
//               <div className="AuthForm-main-form-container AuthForm-main-sign-up-container">
//                 <form>
//                   <h1>Create Account</h1>

//                   <div className="AuthForm-main-infield">
//                     <input
//                       type="text"
//                       placeholder="Name"
//                       name="username"
//                       value={formData.username}
//                       onChange={handleChange}
//                     />
//                     <label></label>
//                   </div>
//                   <div className="AuthForm-main-infield">
//                     <input
//                       type="email"
//                       placeholder="Email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                     />
//                     <label></label>
//                   </div>
//                   <div className="AuthForm-main-infield">
//                     <input
//                       type="number"
//                       placeholder="Mobile"
//                       name="mobile"
//                       value={formData.mobile}
//                       onChange={handleChange}
//                     />
//                     <label></label>
//                   </div>
//                   <div className="AuthForm-main-infield">
//                     <input
//                       type={showPassword ? "text" : "password"}
//                       placeholder="Password"
//                       name="password"
//                       value={formData.password}
//                       onChange={handleChange}
//                     />
//                     <label></label>
//                     <span
//                       onClick={handleTogglePassword}
//                       style={{ cursor: "pointer" }}
//                     >
//                       {showPassword ? <FaEyeSlash /> : <FaEye />}
//                     </span>
//                   </div>
//                   <div className="AuthForm-main-infield">
//                     <input
//                       type={showConfirmPassword ? "text" : "password"}
//                       placeholder="Confirm Password"
//                       name="confirm_password"
//                       value={formData.confirm_password}
//                       onChange={handleChange}
//                     />
//                     <label></label>
//                     <span
//                       onClick={handleToggleConfirmPassword}
//                       style={{ cursor: "pointer" }}
//                     >
//                       {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
//                     </span>
//                   </div>
//                   <button
//                     type="button"
//                     onClick={() => {
//                       handleSignUpSUBMISSIONClick();
//                       // handleSignUpClick();
//                     }}
//                   >
//                     Sign Up
//                   </button>
//                 </form>
//               </div>
//             )}

//             {/* Sign In Form */}
//             {!isSignUp && (
//               <div className="AuthForm-main-form-container AuthForm-main-sign-in-container">
//                 <form>
//                   <h1>LOG IN</h1>

//                   <div className="AuthForm-main-infield">
//                     <input
//                       type="email"
//                       placeholder="Email"
//                       name="email"
//                       value={email}
//                       onChange={(e) => setEmail(e.target.value)}
//                     />
//                     <label></label>
//                   </div>
//                   <div className="AuthForm-main-infield">
//                     <input
//                       type={showPassword ? "password" : "text"}
//                       placeholder="Password"
//                       value={password}
//                       onChange={(e) => setPassword(e.target.value)}
//                     />
//                     <label></label>
//                     <span
//                       onClick={handleTogglePassword}
//                       style={{ cursor: "pointer" }}
//                     >
//                       {showPassword ? <FaEyeSlash /> : <FaEye />}
//                     </span>
//                   </div>
//                   {error && <p className="error-message">{error}</p>}
//                   <a
//                     href="#"
//                     className="AuthForm-main-forgot"
//                     onClick={handleForgotPasswordClick}
//                   >
//                     Forgot your password?
//                   </a>
//                   <button type="button" onClick={handleSignInClick}>
//                     LOG IN
//                   </button>
//                 </form>
//               </div>
//             )}
//           </>
//         ) : null}

//         {isOtpVerification && (
// <div className="AuthForm-main-form-container AuthForm-main-otp-container">
//   <form>
//     <h1>Verify OTP</h1>
//     <div className="AuthForm-main-infield">
//       <input
//         type="email"
//         placeholder="Enter email"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//       />
//       <label></label>
//     </div>

//     <div className="AuthForm-main-infield">
//       <input
//         type="text"
//         placeholder="Enter OTP"
//         value={otp}
//         onChange={(e) => setOtp(e.target.value)}
//       />
//       <label></label>
//     </div>

//     {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

//     <button type="button" onClick={handleOtpVerification}>
//       Verify OTP
//     </button>

//     <Link to="#" onClick={handleBackToSignUp}>
//       Back to Sign Up
//     </Link>
//   </form>
// </div>
//         )}

//         {/* Forgot Password Form */}
//         {isForgotPassword && !isResetPassword && (
//           <div className="AuthForm-main-form-container AuthForm-main-forgot-password-container">
//             <form>
//               <h1>Reset Password</h1>

//               <div className="AuthForm-main-infield">
//                 <input
//                   type="email"
//                   placeholder="Enter your email"
//                   value={emailforget}
//                   onChange={(e) => setEmailforget(e.target.value)}
//                 />
//                 <label></label>
//               </div>

//               <button type="button" onClick={handleSendResetLinkClick}>
//                 Send OTP
//               </button>

//               <Link to="#" onClick={handleBackToSignIn}>
//                 Back to Sign In
//               </Link>
//             </form>
//           </div>
//         )}

//         {/* Reset Password Form */}
//         {isResetPassword && (
//           <div className="AuthForm-main-form-container AuthForm-main-reset-password-container">
//             <form onSubmit={handleResetPasswordSubmit}>
//               <h1>Reset Your Password</h1>

//               <div className="AuthForm-main-infield">
//                 <input
//                   type="email"
//                   placeholder="Enter your email"
//                   value={emailreset}
//                   onChange={(e) => setEmailreset(e.target.value)}
//                 />
//                 <label></label>
//               </div>
//               <div className="AuthForm-main-infield">
//                 <input
//                   type="text"
//                   placeholder="Enter OTP"
//                   value={otpreset}
//                   onChange={(e) => setOtpreset(e.target.value)}
//                 />
//                 <label></label>
//               </div>
//               <div className="AuthForm-main-infield">
//                 <input
//                   // type="password"
//                   type={shownewPassword ? "password" : "text"}
//                   placeholder="New Password"
//                   value={newPassword}
//                   onChange={(e) => setNewPassword(e.target.value)}
//                 />
//                 <label></label>
//                 <span
//                   onClick={handleTogglePassword}
//                   style={{ cursor: "pointer" }}
//                 >
//                   {showPassword ? <FaEyeSlash /> : <FaEye />}
//                 </span>
//               </div>
//               <div className="AuthForm-main-infield">
//                 <input
//                   type={shownewConfirmPassword ? "password" : "text"}
//                   placeholder="Confirm Password"
//                   value={confirmPassword}
//                   onChange={(e) => setConfirmPassword(e.target.value)}
//                 />
//                 <label></label>
//                 <span
//                   onClick={handleToggleConfirmPassword}
//                   style={{ cursor: "pointer" }}
//                 >
//                   {showPassword ? <FaEyeSlash /> : <FaEye />}
//                 </span>
//               </div>

//               <button type="submit">Submit</button>

//               <Link to="#" onClick={handleBackToSignIn}>
//                 Back to Sign In
//               </Link>
//             </form>
//           </div>
//         )}

//         {/* Sign In Confirmation */}
//         {isSignedIn && (
//           <div className="AuthForm-main-form-container AuthForm-main-sign-in-confirmation-container">
//             <h1>Hi User</h1>
//             <p>Your account is successfully signed in</p>
//             <p>Do you want to log out?</p>
//             <button onClick={handleBackToSignIn}>Log Out</button>
//           </div>
//         )}

//         {/* Overlay */}
//         <div className="AuthForm-main-overlay-container">
//           <div className="AuthForm-main-overlay">
//             <div className="AuthForm-main-overlay-panel AuthForm-main-overlay-left">
//               <h1>Welcome Back!</h1>
//               <p>
//                 To keep connected with us, please login with your personal info
//               </p>
//               <button type="button" onClick={handleOverlayClick}>
//                 Sign In
//               </button>
//             </div>
//             <div className="AuthForm-main-overlay-panel AuthForm-main-overlay-right">
//               <h1>Hello, Friend!</h1>
//               <p>Enter your personal details and start your journey with us</p>
//               <button
//                 type="button"
//                 onClick={handleOverlayClick}
//                 className="pt-3 pt-md-0 pr-3 pr-md-0"
//               >
//                 Sign Up
//               </button>
//             </div>
//           </div>
//           <button
//             className="AuthForm-main-overlay-button"
//             onClick={handleOverlayClick}
//           ></button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AuthForm;

// import React, { useState } from "react";
// import Swal from "sweetalert2";
// import { Link } from "react-router-dom";
// import { FaEye, FaEyeSlash } from "react-icons/fa";

// import {
//   signupUser,
//   OtpUser,
//   // loginUser,
//   requestPasswordReset,
//   confirmPasswordReset,
// } from "./Apiservice";
// import Modal from "react-modal";
// const AuthForm = () => {
//   const [loginData, setLoginData] = useState({ email: "", password: "" });
//   const [showloginPassword, setShowloginPassword] = useState(false);
//   const [registerData, setRegisterData] = useState({
//     username: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//   });

//   const getCookie = (name) => {
//     let cookieValue = null;
//     if (document.cookie && document.cookie !== "") {
//       const cookies = document.cookie.split(";");
//       for (let i = 0; i < cookies.length; i++) {
//         const cookie = cookies[i].trim();
//         if (cookie.substring(0, name.length + 1) === name + "=") {
//           cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//           break;
//         }
//       }
//     }
//     return cookieValue;
//   };

//   const csrftoken = getCookie("csrftoken");

//   const handleLogin = (e) => {
//     e.preventDefault();

//     fetch("https://infiboutique.in/api/api/login/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "X-CSRFToken": csrftoken,
//       },
//       body: JSON.stringify(loginData),
//     })
//       .then((response) => {
//         if (response.ok) {
//           return response.json();
//         }
//         throw new Error("Login failed. Please check your credentials.");
//       })
//       .then((data) => {
//         Swal.fire({
//           icon: "success",
//           title: "Login Successful",
//           text: "You are now logged in.",
//           confirmButtonText: "OK",
//         }).then(() => {
//           localStorage.setItem("userId", data.user.user_id);
//           localStorage.setItem("username", data.user.username);
//           window.location.href = "/";
//         });
//       })
//       .catch((error) => {
//         Swal.fire({
//           icon: "error",
//           title: "Login Failed",
//           text: error.message,
//           confirmButtonText: "OK",
//         });
//       });
//   };

//   const toggleloginPassword = () => {
//     setShowloginPassword(!showloginPassword);
//   };
//   const [showregisterNewPassword, setShowregisterNewPassword] = useState(false);
//   const [showregisterConfirmPassword, setShowregisterConfirmPassword] =
//     useState(false);
//   const handleRegister = async (e) => {
//     e.preventDefault();

//     if (registerData.password !== registerData.confirmPassword) {
//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: "Passwords do not match",
//         confirmButtonText: "Try Again",
//       });
//       return;
//     }

//     try {
//       const payload = {
//         username: registerData.username,
//         email: registerData.email,
//         password: registerData.password,
//         confirm_password: registerData.confirmPassword,
//         mobile: registerData.mobile, // Include the mobile field
//       };

//       const response = await signupUser(payload);
//       console.log("Registration response:", response);

//       Swal.fire({
//         icon: "success",
//         title: "Registration Successful",
//         text: "Please verify your OTP.",
//         confirmButtonText: "OK",
//       }).then(() => {
//         setEmailForOtp(registerData.email);
//         setRegisterData({
//           username: "",
//           email: "",
//           password: "",
//           confirmPassword: "",
//           mobile: "", // Reset mobile field
//         });
//         setIsOtpModalOpen(true);
//       });
//     } catch (error) {
//       let errorMessage = "Registration failed. Please try again.";

//       // Handle specific backend error messages
//       if (error.response) {
//         if (error.response.data?.confirm_password) {
//           errorMessage = error.response.data.confirm_password[0];
//         } else if (error.response.data?.mobile) {
//           errorMessage = error.response.data.mobile[0];
//         } else if (error.response.data?.email) {
//           errorMessage = error.response.data.email[0];
//         }
//       }

//       Swal.fire({
//         icon: "error",
//         title: "Registration Failed",
//         text: errorMessage,
//         confirmButtonText: "Try Again",
//       });
//     }
//   };
//   const toggleregisterNewPassword = () => {
//     setShowregisterNewPassword(!showregisterNewPassword);
//   };

//   const toggleregisterConfirmPassword = () => {
//     setShowregisterConfirmPassword(!showregisterConfirmPassword);
//   };

//   const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
//   const [otp, setOtp] = useState("");
//   const [emailForOtp, setEmailForOtp] = useState("");
//   const handleOtpVerification = async () => {
//     try {
//       await OtpUser({
//         email: emailForOtp,
//         code: otp,
//       });

//       Swal.fire({
//         title: "OTP Verified successfully!",
//         text: "You have successfully verified your OTP. Please log in.",
//         icon: "success",
//         confirmButtonText: "OK",
//       });

//       setOtp("");
//       setEmailForOtp("");
//       setIsOtpModalOpen(false); // Close OTP modal
//     } catch (error) {
//       Swal.fire({
//         title: "Error!",
//         text: "OTP verification failed. Please try again.",
//         icon: "error",
//         confirmButtonText: "Try Again",
//       });
//     }
//   };

//   const [emailforget, setEmailforget] = useState(""); // For forgot password email
//   const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
//   const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
//   const [emailreset, setEmailreset] = useState("");
//   const handleSendResetLinkClick = async () => {
//     try {
//       await requestPasswordReset(emailforget); // Call the password reset function
//       Swal.fire({
//         title: "Success!",
//         text: "Password reset OTP sent to your email.",
//         icon: "success",
//         confirmButtonText: "OK",
//       });
//       setIsForgotPasswordOpen(false);

//       setEmailreset(emailforget); // Store the email in state
//       setIsResetPasswordOpen(true);
//     } catch (error) {
//       Swal.fire({
//         title: "Error!",
//         text: "Failed to send OTP. Please check the email.",
//         icon: "error",
//         confirmButtonText: "Try Again",
//       });
//     }
//   };
//   const [error, setError] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [otpreset, setOtpreset] = useState("");
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const handleResetPasswordSubmit = async (e) => {
//     e.preventDefault();
//     if (newPassword !== confirmPassword) {
//       setError("Passwords do not match.");
//       return;
//     }

//     try {
//       const userData = {
//         email: emailreset, // Use email from reset state
//         otp: otpreset,
//         new_password: newPassword,
//         confirm_password: confirmPassword,
//       };
//       await confirmPasswordReset(userData);

//       Swal.fire({
//         title: "Success!",
//         text: "Password reset successful. You can now log in.",
//         icon: "success",
//         confirmButtonText: "OK",
//       });
//       setEmailreset("");
//       setOtpreset("");
//       setNewPassword("");
//       setConfirmPassword("");
//       setIsResetPasswordOpen(false); // Close reset password modal
//     } catch (error) {
//       Swal.fire({
//         title: "Error!",
//         text: "Failed to reset password. Please check your details.",
//         icon: "error",
//         confirmButtonText: "Try Again",
//       });
//       console.error(
//         "Error details: ",
//         error.response ? error.response.data : error.message
//       );
//     }
//   };
//   const toggleNewPassword = () => {
//     setShowNewPassword(!showNewPassword);
//   };

//   const toggleConfirmPassword = () => {
//     setShowConfirmPassword(!showConfirmPassword);
//   };

//   return (
//     <div className="auth-container-infi">
//       <div className="auth-form">
//         <div className="login-section">
//           <h2>Login</h2>
//           <form onSubmit={handleLogin}>
//             <label htmlFor="login-email">Email Address *</label>
//             <input
//               type="email"
//               id="login-email"
//               value={loginData.email}
//               onChange={(e) =>
//                 setLoginData({ ...loginData, email: e.target.value })
//               }
//               placeholder="Enter your email"
//               required
//             />

//             <label htmlFor="login-password">Password *</label>
//             <div className="password-input-container">
//               <input
//                 type={showloginPassword ? "text" : "password"}
//                 id="login-password"
//                 value={loginData.password}
//                 onChange={(e) =>
//                   setLoginData({ ...loginData, password: e.target.value })
//                 }
//                 placeholder="Enter your password"
//                 required
//               />
//               <span
//                 className="password-togglelogin"
//                 onClick={toggleloginPassword}
//               >
//                 {showloginPassword ? <FaEyeSlash /> : <FaEye />}
//               </span>
//             </div>
//             <div className="login-actions">
//               <button type="submit" className="login-button">
//                 Log in
//               </button>
//             </div>
//             <Link
//               to="#"
//               className="forgot-password"
//               onClick={() => setIsForgotPasswordOpen(true)} // Open modal
//             >
//               Lost your password?
//             </Link>
//           </form>
//         </div>

//         <div className="register-section">
//           <h2>Register</h2>
//           <form onSubmit={handleRegister}>
//             <label htmlFor="register-username">Username *</label>
//             <input
//               type="text"
//               id="register-username"
//               value={registerData.username}
//               onChange={(e) =>
//                 setRegisterData({ ...registerData, username: e.target.value })
//               }
//               placeholder="Enter a username"
//               required
//             />

//             <label htmlFor="register-email">Email Address *</label>
//             <input
//               type="email"
//               id="register-email"
//               value={registerData.email}
//               onChange={(e) =>
//                 setRegisterData({ ...registerData, email: e.target.value })
//               }
//               placeholder="Enter your email"
//               required
//             />

//             <label htmlFor="register-mobile">Mobile *</label>
//             <input
//               type="text"
//               id="register-mobile"
//               value={registerData.mobile}
//               onChange={(e) =>
//                 setRegisterData({ ...registerData, mobile: e.target.value })
//               }
//               placeholder="Enter your mobile number"
//               required
//             />

//             <label htmlFor="register-password">Password *</label>
//             <div className="password-input-container">
//               <input
//                 type={showregisterNewPassword ? "text" : "password"}
//                 id="register-password"
//                 value={registerData.password}
//                 onChange={(e) =>
//                   setRegisterData({ ...registerData, password: e.target.value })
//                 }
//                 placeholder="Enter your password"
//                 required
//               />
//               <span
//                 className="password-togglelogin"
//                 onClick={toggleregisterNewPassword}
//               >
//                 {showregisterNewPassword ? <FaEyeSlash /> : <FaEye />}
//               </span>
//             </div>

//             <label htmlFor="register-confirm-password">
//               Confirm Password *
//             </label>
//             <div className="password-input-container">
//               <input
//                 type={showregisterConfirmPassword ? "text" : "password"}
//                 id="register-confirm-password"
//                 value={registerData.confirmPassword}
//                 onChange={(e) =>
//                   setRegisterData({
//                     ...registerData,
//                     confirmPassword: e.target.value,
//                   })
//                 }
//                 placeholder="Confirm your password"
//                 required
//               />

//               <span
//                 className="password-togglelogin"
//                 onClick={toggleregisterConfirmPassword}
//               >
//                 {showregisterConfirmPassword ? <FaEyeSlash /> : <FaEye />}
//               </span>
//             </div>
//             <button type="submit" className="register-button">
//               Register
//             </button>
//           </form>
//         </div>
//       </div>

//       <Modal
//         isOpen={isForgotPasswordOpen}
//         onRequestClose={() => setIsForgotPasswordOpen(false)}
//         contentLabel="Forgot Password"
//         className="ModalContent-infi"
//         overlayClassName="ModalOverlay-infi"
//       >
//         <div className="ModalHeader-infi">
//           <h2>Reset Your Password</h2>
//         </div>
//         <div className="ModalDescription-infi">
//           <p>
//             Please enter your email address to receive a password reset link.
//           </p>
//         </div>
//         <input
//           type="email"
//           value={emailforget}
//           onChange={(e) => setEmailforget(e.target.value)}
//           className="ModalInput-infi"
//           placeholder="Enter your email"
//           required
//         />
//         <button onClick={handleSendResetLinkClick} className="ModalButton-infi">
//           Send Reset Link
//         </button>
//         <button
//           onClick={() => setIsForgotPasswordOpen(false)}
//           className="CloseButton-infi"
//         >
//           Close
//         </button>
//       </Modal>

//       <Modal
//         isOpen={isResetPasswordOpen}
//         onRequestClose={() => setIsResetPasswordOpen(false)}
//         contentLabel="Reset Password"
//         className="ModalContent-infi"
//         overlayClassName="ModalOverlay-infi"
//       >
//         <div className="ModalHeader-infi">
//           <h2>Reset Your Password</h2>
//         </div>
//         <form onSubmit={handleResetPasswordSubmit}>
//           <div className="ModalDescription-infi">
//             <p>Enter the OTP sent to {emailreset}</p>
//           </div>
//           <input
//             type="text"
//             value={otpreset}
//             onChange={(e) => setOtpreset(e.target.value)}
//             className="ModalInput-infi"
//             placeholder="Enter OTP"
//             required
//           />
//           <div className="password-input-container">
//             <input
//               type={showNewPassword ? "text" : "password"} // Toggle between text and password type
//               value={newPassword}
//               onChange={(e) => setNewPassword(e.target.value)}
//               className="ModalInput-infi"
//               placeholder="Enter new password"
//               required
//             />
//             <span className="password-toggle" onClick={toggleNewPassword}>
//               {showNewPassword ? <FaEyeSlash /> : <FaEye />}
//             </span>
//           </div>
//           <div className="password-input-container">
//             <input
//               type={showConfirmPassword ? "text" : "password"} // Toggle between text and password type
//               value={confirmPassword}
//               onChange={(e) => setConfirmPassword(e.target.value)}
//               className="ModalInput-infi"
//               placeholder="Confirm new password"
//               required
//             />
//             <span className="password-toggle" onClick={toggleConfirmPassword}>
//               {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
//             </span>
//           </div>
//           {error && <p style={{ color: "red" }}>{error}</p>}
//           <button type="submit" className="ModalButton-infi">
//             Reset Password
//           </button>
//         </form>
//         <button
//           onClick={() => setIsResetPasswordOpen(false)}
//           className="CloseButton-infi"
//         >
//           Close
//         </button>
//       </Modal>

//       <Modal
//         isOpen={isOtpModalOpen}
//         onRequestClose={() => setIsOtpModalOpen(false)}
//         contentLabel="OTP Verification"
//         className="ModalContent-infi" // Modal content
//         overlayClassName="ModalOverlay-infi" // Overlay behind modal
//       >
//         <div className="ModalHeader-infi">
//           <h2>Verify OTP</h2>
//         </div>
//         <div className="ModalDescription-infi">
//           <p>Please enter the OTP sent to {emailForOtp}</p>
//         </div>
//         <input
//           type="text"
//           value={otp}
//           onChange={(e) => setOtp(e.target.value)}
//           className="ModalInput-infi"
//           placeholder="Enter OTP"
//           required
//         />
//         <button onClick={handleOtpVerification} className="ModalButton-infi">
//           Verify OTP
//         </button>
//         <button
//           onClick={() => setIsOtpModalOpen(false)}
//           className="CloseButton-infi"
//         >
//           Close
//         </button>
//       </Modal>
//     </div>
//   );
// };

// export default AuthForm;

import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import {
  signupUser,
  OtpUser,
  // loginUser,
  requestPasswordReset,
  confirmPasswordReset,
} from "./Apiservice";
import Modal from "react-modal";
const AuthForm = () => {
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [showloginPassword, setShowloginPassword] = useState(false);
  const [registerData, setRegisterData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const csrftoken = getCookie("csrftoken");

  const handleLogin = (e) => {
    e.preventDefault();

    fetch("https://infiboutique.in/api/api/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify(loginData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Login failed. Please check your credentials.");
      })
      .then((data) => {
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "You are now logged in.",
          confirmButtonText: "OK",
        }).then(() => {
          localStorage.setItem("userId", data.user.user_id);
          localStorage.setItem("username", data.user.username);
          window.location.href = "/";
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: error.message,
          confirmButtonText: "OK",
        });
      });
  };

  const toggleloginPassword = () => {
    setShowloginPassword(!showloginPassword);
  };
  const [showregisterNewPassword, setShowregisterNewPassword] = useState(false);
  const [showregisterConfirmPassword, setShowregisterConfirmPassword] =
    useState(false);
  const handleRegister = async (e) => {
    e.preventDefault();

    if (registerData.password !== registerData.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Passwords do not match",
        confirmButtonText: "Try Again",
      });
      return;
    }

    try {
      const payload = {
        username: registerData.username,
        email: registerData.email,
        password: registerData.password,
        confirm_password: registerData.confirmPassword,
        mobile: registerData.mobile,
      };

      const response = await signupUser(payload);
      console.log("Registration response:", response);

      Swal.fire({
        icon: "success",
        title: "Registration Successful",
        text: "Please verify your OTP.",
        confirmButtonText: "OK",
      }).then(() => {
        setEmailForOtp(registerData.email);
        setRegisterData({
          username: "",
          email: "",
          password: "",
          confirmPassword: "",
          mobile: "",
        });
        setIsOtpModalOpen(true);

        // Store user email and password for auto-login after OTP verification
        localStorage.setItem("userEmail", registerData.email);
        localStorage.setItem("userPassword", registerData.password);
      });
    } catch (error) {
      let errorMessage = "Registration failed. Please try again.";
      if (error.response) {
        if (error.response.data?.confirm_password) {
          errorMessage = error.response.data.confirm_password[0];
        } else if (error.response.data?.mobile) {
          errorMessage = error.response.data.mobile[0];
        } else if (error.response.data?.email) {
          errorMessage = error.response.data.email[0];
        }
      }
      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text: errorMessage,
        confirmButtonText: "Try Again",
      });
    }
  };

  const toggleregisterNewPassword = () => {
    setShowregisterNewPassword(!showregisterNewPassword);
  };

  const toggleregisterConfirmPassword = () => {
    setShowregisterConfirmPassword(!showregisterConfirmPassword);
  };

  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [emailForOtp, setEmailForOtp] = useState("");
  const handleOtpVerification = async () => {
    try {
      await OtpUser({
        email: emailForOtp,
        code: otp,
      });

      Swal.fire({
        title: "OTP Verified successfully!",
        text: "You have successfully verified your OTP. Please log in.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(async () => {
        setIsOtpModalOpen(false);
        const userEmail = localStorage.getItem("userEmail");
        const userPassword = localStorage.getItem("userPassword");

        const loginResponse = await fetch(
          "https://infiboutique.in/api/api/login/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrftoken,
            },
            body: JSON.stringify({
              email: userEmail,
              password: userPassword,
            }),
          }
        );

        if (loginResponse.ok) {
          const data = await loginResponse.json();
          localStorage.setItem("userId", data.user.user_id);
          localStorage.setItem("username", data.user.username);

          // Show success message
          Swal.fire({
            title: "Login Successful",
            text: "You have logged in successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.href = "/";
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Login Failed",
            text: "Auto-login failed. Please try again.",
            confirmButtonText: "OK",
          });
        }
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "OTP verification failed. Please try again.",
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
  };

  const [emailforget, setEmailforget] = useState(""); // For forgot password email
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
  const [emailreset, setEmailreset] = useState("");
  const handleSendResetLinkClick = async () => {
    try {
      await requestPasswordReset(emailforget); // Call the password reset function
      Swal.fire({
        title: "Success!",
        text: "Password reset OTP sent to your email.",
        icon: "success",
        confirmButtonText: "OK",
      });
      setIsForgotPasswordOpen(false);

      setEmailreset(emailforget); // Store the email in state
      setIsResetPasswordOpen(true);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to send OTP. Please check the email.",
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
  };
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpreset, setOtpreset] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const userData = {
        email: emailreset, // Use email from reset state
        otp: otpreset,
        new_password: newPassword,
        confirm_password: confirmPassword,
      };
      await confirmPasswordReset(userData);

      Swal.fire({
        title: "Success!",
        text: "Password reset successful. You can now log in.",
        icon: "success",
        confirmButtonText: "OK",
      });
      setEmailreset("");
      setOtpreset("");
      setNewPassword("");
      setConfirmPassword("");
      setIsResetPasswordOpen(false);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to reset password. Please check your details.",
        icon: "error",
        confirmButtonText: "Try Again",
      });
      console.error(
        "Error details: ",
        error.response ? error.response.data : error.message
      );
    }
  };
  const toggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    window.location.href = "/";
  };
  const isLoggedIn = localStorage.getItem("userId") !== null;

  const [isRegisterClicked, setIsRegisterClicked] = useState(false);

  const handleRegisterClick = () => {
    setIsRegisterClicked(true);
  };

  const resetBorderColor = () => {
    setIsRegisterClicked(false);
  };
  return (
    <>
      {isLoggedIn ? (
        <div className="user-main">
          <div className="user-card">
            <div className="user-card-header">
              <img
                className="user-card-image"
                src="./assets/img/dummyimg.jpg" // Dummy image
                alt="User"
              />
              <h3>Hi, {localStorage.getItem("username")}</h3>
            </div>
            <div className="user-card-body">
              <button onClick={handleLogout} className="logout-btn">
                Logout
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="auth-container-infi">
          <div className="auth-form">
            <div className="login-section">
              <h2>Login</h2>
              <form onSubmit={handleLogin}>
                <label htmlFor="login-email">Email Address *</label>
                <input
                  type="email"
                  id="login-email"
                  value={loginData.email}
                  onChange={(e) =>
                    setLoginData({ ...loginData, email: e.target.value })
                  }
                  placeholder="Enter your email"
                  required
                />

                <label htmlFor="login-password">Password *</label>
                <div className="password-input-container">
                  <input
                    type={showloginPassword ? "text" : "password"}
                    id="login-password"
                    value={loginData.password}
                    onChange={(e) =>
                      setLoginData({ ...loginData, password: e.target.value })
                    }
                    placeholder="Enter your password"
                    required
                  />
                  <span
                    className="password-togglelogin"
                    onClick={toggleloginPassword}
                  >
                    {showloginPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <div className="login-actions">
                  <button type="submit" className="login-button">
                    Log in
                  </button>
                </div>
                <Link
                  to="#"
                  className="forgot-password"
                  onClick={() => setIsForgotPasswordOpen(true)} // Open modal
                >
                  Forget password?
                </Link>
                <p className="text-center mt-3">
                  If you don't have an account please
                  <span
                    style={{ color: "#6c63ff", cursor: "pointer" }}
                    onClick={handleRegisterClick}
                  >
                    {" "}
                    register?
                  </span>
                </p>
              </form>
            </div>

            <div className="register-section">
              <h2>Register</h2>
              <form onSubmit={handleRegister}>
                <label htmlFor="register-username">Username *</label>
                <input
                  type="text"
                  id="register-username"
                  value={registerData.username}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      username: e.target.value,
                    })
                  }
                  style={{
                    borderColor: isRegisterClicked ? "red" : "",
                    borderWidth: isRegisterClicked ? "1px" : "",
                  }}
                  onFocus={resetBorderColor} // Reset border color on focus
                  placeholder="Enter a username"
                  required
                />

                <label htmlFor="register-email">Email Address *</label>
                <input
                  type="email"
                  id="register-email"
                  value={registerData.email}
                  onChange={(e) =>
                    setRegisterData({ ...registerData, email: e.target.value })
                  }
                  placeholder="Enter your email"
                  required
                  style={{
                    borderColor: isRegisterClicked ? "red" : "",
                    borderWidth: isRegisterClicked ? "1px" : "",
                  }}
                  onFocus={resetBorderColor}
                />

                <label htmlFor="register-mobile">Mobile *</label>
                <input
                  type="text"
                  id="register-mobile"
                  value={registerData.mobile}
                  onChange={(e) =>
                    setRegisterData({ ...registerData, mobile: e.target.value })
                  }
                  placeholder="Enter your mobile number"
                  required
                  style={{
                    borderColor: isRegisterClicked ? "red" : "",
                    borderWidth: isRegisterClicked ? "1px" : "",
                  }}
                  onFocus={resetBorderColor}
                />

                <label htmlFor="register-password">Password *</label>
                <div className="password-input-container">
                  <input
                    type={showregisterNewPassword ? "text" : "password"}
                    id="register-password"
                    value={registerData.password}
                    onChange={(e) =>
                      setRegisterData({
                        ...registerData,
                        password: e.target.value,
                      })
                    }
                    placeholder="Enter your password"
                    required
                    style={{
                      borderColor: isRegisterClicked ? "red" : "",
                      borderWidth: isRegisterClicked ? "1px" : "",
                    }}
                    onFocus={resetBorderColor}
                  />
                  <span
                    className="password-togglelogin"
                    onClick={toggleregisterNewPassword}
                  >
                    {showregisterNewPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>

                <label htmlFor="register-confirm-password">
                  Confirm Password *
                </label>
                <div className="password-input-container">
                  <input
                    type={showregisterConfirmPassword ? "text" : "password"}
                    id="register-confirm-password"
                    value={registerData.confirmPassword}
                    onChange={(e) =>
                      setRegisterData({
                        ...registerData,
                        confirmPassword: e.target.value,
                      })
                    }
                    placeholder="Confirm your password"
                    required
                    style={{
                      borderColor: isRegisterClicked ? "red" : "",
                      borderWidth: isRegisterClicked ? "1px" : "",
                    }}
                    onFocus={resetBorderColor}
                  />

                  <span
                    className="password-togglelogin"
                    onClick={toggleregisterConfirmPassword}
                  >
                    {showregisterConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <button type="submit" className="register-button">
                  Register
                </button>
              </form>
            </div>
          </div>

          <Modal
            isOpen={isForgotPasswordOpen}
            onRequestClose={() => setIsForgotPasswordOpen(false)}
            contentLabel="Forgot Password"
            className="ModalContent-infi"
            overlayClassName="ModalOverlay-infi"
          >
            <div className="ModalHeader-infi">
              <h2>Reset Your Password</h2>
            </div>
            <div className="ModalDescription-infi">
              <p>
                Please enter your email address to receive a password reset
                link.
              </p>
            </div>
            <input
              type="email"
              value={emailforget}
              onChange={(e) => setEmailforget(e.target.value)}
              className="ModalInput-infi"
              placeholder="Enter your email"
              required
            />
            <button
              onClick={handleSendResetLinkClick}
              className="ModalButton-infi"
            >
              Send Reset Link
            </button>
            <button
              onClick={() => setIsForgotPasswordOpen(false)}
              className="CloseButton-infi"
            >
              Close
            </button>
          </Modal>

          <Modal
            isOpen={isResetPasswordOpen}
            onRequestClose={() => setIsResetPasswordOpen(false)}
            contentLabel="Reset Password"
            className="ModalContent-infi"
            overlayClassName="ModalOverlay-infi"
          >
            <div className="ModalHeader-infi">
              <h2>Reset Your Password</h2>
            </div>
            <form onSubmit={handleResetPasswordSubmit}>
              <div className="ModalDescription-infi">
                <p>Enter the OTP sent to {emailreset}</p>
              </div>
              <input
                type="text"
                value={otpreset}
                onChange={(e) => setOtpreset(e.target.value)}
                className="ModalInput-infi"
                placeholder="Enter OTP"
                required
              />
              <div className="password-input-container">
                <input
                  type={showNewPassword ? "text" : "password"} // Toggle between text and password type
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="ModalInput-infi"
                  placeholder="Enter new password"
                  required
                />
                <span className="password-toggle" onClick={toggleNewPassword}>
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <div className="password-input-container">
                <input
                  type={showConfirmPassword ? "text" : "password"} // Toggle between text and password type
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="ModalInput-infi"
                  placeholder="Confirm new password"
                  required
                />
                <span
                  className="password-toggle"
                  onClick={toggleConfirmPassword}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <button type="submit" className="ModalButton-infi">
                Reset Password
              </button>
            </form>
            <button
              onClick={() => setIsResetPasswordOpen(false)}
              className="CloseButton-infi"
            >
              Close
            </button>
          </Modal>

          <Modal
            isOpen={isOtpModalOpen}
            onRequestClose={() => setIsOtpModalOpen(false)}
            contentLabel="OTP Verification"
            className="ModalContent-infi" // Modal content
            overlayClassName="ModalOverlay-infi" // Overlay behind modal
          >
            <div className="ModalHeader-infi">
              <h2>Verify OTP</h2>
            </div>
            <div className="ModalDescription-infi">
              <p>Please enter the OTP sent to {emailForOtp}</p>
            </div>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="ModalInput-infi"
              placeholder="Enter OTP"
              required
            />
            <button
              onClick={handleOtpVerification}
              className="ModalButton-infi"
            >
              Verify OTP
            </button>
            <button
              onClick={() => setIsOtpModalOpen(false)}
              className="CloseButton-infi"
            >
              Close
            </button>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AuthForm;
