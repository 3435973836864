// import React from "react";
// import { Link } from "react-router-dom";

// const Footer = () => {
//   return (
//     <div>
//       <section class="newsletter_section newsletter_padding border-top">
//         <div class="container">
//           <div class="row">
//             <div class="col-12">
//               <div class="newsletter_inner d-flex justify-content-between align-items-center">
//                 <div class="newsletter_text">
//                   <h3>keep connected</h3>
//                   <p>Get updates by subscribe our weekly newsletter</p>
//                 </div>
//                 <div class="newsletter_subscribe">
//                   <form id="mc-form">
//                     <input
//                       class="border-0"
//                       id="mc-email"
//                       type="email"
//                       autocomplete="off"
//                       placeholder="Enter your email address"
//                     />
//                     <i class="icon-envelope-open icons"></i>
//                     <button class="border-0" id="mc-submit">
//                       Subscribe
//                     </button>
//                   </form>

//                   <div class="mailchimp-alerts text-centre">
//                     <div class="mailchimp-submitting"></div>
//                     <div class="mailchimp-success"></div>
//                     <div class="mailchimp-error"></div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <footer className="footer_widgets">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-5 col-md-5 col-sm-5">
//               <div className="footer_widget_list">
//                 <div className="footer_logo">
//                   <Link to="#">
//                     <img
//                       src="./assets/img/logo/logo.jpg"
//                       alt=""
//                       style={{ height: "100px" }}
//                     />
//                   </Link>
//                   {/* <p>INFI BOUTIQUE</p> */}
//                 </div>
//                 <div className="footer_contact">
//                   <div className="footer_contact_list">
//                     <span>Our Location</span>
//                     <p>Thanjavur, Tamil Nadu.</p>
//                   </div>
//                   <div className="footer_contact_list">
//                     <span>Mail:</span>
//                     <Link to="mailto:customercare.infi@gmail.com">
//                       customercare.infi@gmail.com
//                     </Link>
//                   </div>
//                   <div className="footer_contact_list">
//                     <span>24/7 hotline:</span>
//                     <Link
//                       to="tel:+91 9894407931
//                     "
//                     >
//                       9894407931
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-7 col-sm-7">
//               <div className="footer_widget_list text-right">
//                 <div className="footer_menu">
//                   <ul className="d-flex justify-content-end">
//                     <li>
//                       <Link to="/">Home</Link>
//                     </li>
//                     <li>
//                       <Link to="/Contact">Contact</Link>
//                     </li>

//                     <li>
//                       <Link to="/Cart">Cart</Link>
//                     </li>
//                   </ul>
//                   <ul className="d-flex justify-content-end">
//                     <li>
//                       <Link to="/AuthForm">signup/login</Link>
//                     </li>
//                     <li>
//                       <Link to="/TermsAndConditions">Terms&Conditions</Link>
//                     </li>
//                   </ul>
//                   {/* <ul className="d-flex justify-content-end">
//                     <li>
//                       <Link to="/Privacypolicy">privacy policy</Link>
//                     </li>
//                     <li>
//                       <Link to="/Returnpolicy">Return Policy</Link>
//                     </li>

//                     <li>
//                       <Link to="/Refundpolicy">Refund policy</Link>
//                     </li>
//                     <li>
//                       <Link to="/Shippingpolicy">Shipping Policy</Link>
//                     </li>
//                   </ul> */}
//                 </div>
//                 <div className="footer_social">
//                   <ul className="d-flex justify-content-end">
//                     <li>
//                       <Link
//                         to="https://twitter.com"
//                         data-tippy="Twitter"
//                         data-tippy-inertia="true"
//                         data-tippy-delay="50"
//                         data-tippy-arrow="true"
//                         data-tippy-placement="top"
//                       >
//                         <i className="ion-social-twitter"></i>
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to="https://www.facebook.com"
//                         data-tippy="Facebook"
//                         data-tippy-inertia="true"
//                         data-tippy-delay="50"
//                         data-tippy-arrow="true"
//                         data-tippy-placement="top"
//                       >
//                         <i className="ion-social-facebook"></i>
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to="https://www.google.com"
//                         data-tippy="googleplus"
//                         data-tippy-inertia="true"
//                         data-tippy-delay="50"
//                         data-tippy-arrow="true"
//                         data-tippy-placement="top"
//                       >
//                         <i className="ion-social-googleplus-outline"></i>
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to="https://www.instagram.com"
//                         data-tippy="Instagram"
//                         data-tippy-inertia="true"
//                         data-tippy-delay="50"
//                         data-tippy-arrow="true"
//                         data-tippy-placement="top"
//                       >
//                         <i className="ion-social-instagram-outline"></i>
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to="https://www.youtube.com"
//                         data-tippy="Youtube"
//                         data-tippy-inertia="true"
//                         data-tippy-delay="50"
//                         data-tippy-arrow="true"
//                         data-tippy-placement="top"
//                       >
//                         <i className="ion-social-youtube"></i>
//                       </Link>
//                     </li>
//                   </ul>
//                 </div>
//                 <div className="copyright_right">
//                   <p>
//                     ©2024 <span>Infi</span> made with{" "}
//                     <i className="ion-heart"></i> by{" "}
//                     <Link to="https://hasthemes.com" target="_blank">
//                       Brandingworld
//                     </Link>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default Footer;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Footer = () => {
  // State to handle form input and alerts
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle email change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsSubmitting(true); // Show "submitting" status

    try {
      const response = await fetch(
        "https://infiboutique.in/api/api/Subscribe/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }), // Sending email as JSON
        }
      );

      const result = await response.json();

      if (response.ok) {
        // Show success alert
        Swal.fire({
          icon: "success",
          title: "Subscribed Successfully",
          text: "Thank you for subscribing to our newsletter!",
        });
      } else {
        // Show error alert
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      // Show error alert if API fails
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred. Please try again later.",
      });
    } finally {
      setIsSubmitting(false); // Hide "submitting" status
    }
  };

  return (
    <div>
      <section className="newsletter_section newsletter_padding border-top">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="newsletter_inner d-flex justify-content-between align-items-center">
                <div className="newsletter_text">
                  <h3>Keep connected</h3>
                  <p>Get updates by subscribing to our weekly newsletter</p>
                </div>
                <div className="newsletter_subscribe">
                  <form id="mc-form" onSubmit={handleSubmit}>
                    <input
                      className="border-0"
                      id="mc-email"
                      type="email"
                      autocomplete="off"
                      placeholder="Enter your email address"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                    <i className="icon-envelope-open icons"></i>
                    <button
                      className="border-0"
                      id="mc-submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Subscribe"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer_widgets">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-5">
              <div className="footer_widget_list">
                <div className="footer_logo">
                  <Link to="#">
                    <img
                      src="./assets/img/logo/logo.jpg"
                      alt="Logo"
                      style={{ height: "100px" }}
                    />
                  </Link>
                </div>
                <div className="footer_contact">
                  <div className="footer_contact_list">
                    <span>Our Location</span>
                    <p>Thanjavur, Tamil Nadu.</p>
                  </div>
                  <div className="footer_contact_list">
                    <span>Mail:</span>
                    <Link to="mailto:customercare.infi@gmail.com">
                      customercare.infi@gmail.com
                    </Link>
                  </div>
                  <div className="footer_contact_list">
                    <span>24/7 hotline:</span>
                    <Link to="tel:+91 9894407931">9894407931</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-7">
              <div className="footer_widget_list text-right">
                <div className="footer_menu">
                  <ul className="d-flex justify-content-end">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/Cart">Cart</Link>
                    </li>
                  </ul>
                  <ul className="d-flex justify-content-end">
                    <li>
                      <Link to="/AuthForm">SignUp/Login</Link>
                    </li>
                    <li>
                      <Link to="/TermsAndConditions">Terms & Conditions</Link>
                    </li>
                  </ul>
                </div>
                <div className="footer_social">
                  <ul className="d-flex justify-content-end align-items-center flex-column flex-md-row ">
                    <li>DM for international shipping</li>
                    <li>Follow Us</li>
                    <li>
                      <Link
                        to="https://www.instagram.com/infi__boutique?igsh=MWYwZmw0cjlwazd6dw=="
                        target="_blank"
                      >
                        <i className="ion-social-instagram-outline"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="copyright_right">
                  <p>
                    ©2024 <span>Infi</span> made with{" "}
                    <i className="ion-heart"></i> by{" "}
                    <Link to="https://hasthemes.com" target="_blank">
                      Brandingworld
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
