import { useEffect } from "react";
import React from "react";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <div className="refund-policy container mt-5 mb-5">
      <h1 className="text-center">Refund Policy</h1>
      <p>
        Thank you for shopping with us! We strive to provide the best products
        and services. However, if you are not completely satisfied with your
        purchase, we’re here to help.
      </p>

      <h2>Returns</h2>
      <p>
        You have 30 days to return an item from the date you received it. To be
        eligible for a return, your item must be unused and in the same
        condition that you received it. It must also be in the original
        packaging.
      </p>
      <p>To complete your return, we require a receipt or proof of purchase.</p>

      <h2>Refunds</h2>
      <p>
        Once we receive your item, we will inspect it and notify you that we
        have received your returned item. We will immediately notify you of the
        status of your refund after inspecting the item.
      </p>
      <p>
        If your return is approved, we will initiate a refund to your original
        method of payment. You will receive the credit within a certain amount
        of days, depending on your card issuer's policies.
      </p>

      <h2>Late or Missing Refunds</h2>
      <p>
        If you haven’t received a refund yet, first check your bank account
        again. Then contact your credit card company, it may take some time
        before your refund is officially posted.
      </p>
      <p>
        If you’ve done all of this and you still have not received your refund,
        please contact us at [your support email].
      </p>

      <h2>Exchanges</h2>
      <p>
        We only replace items if they are defective or damaged. If you need to
        exchange it for the same item, send us an email at [your support email]
        and send your item to [your return address].
      </p>

      <h2>Shipping</h2>
      <p>
        To return your product, you should mail your product to [your return
        address]. You will be responsible for paying for your own shipping costs
        for returning your item. Shipping costs are non-refundable.
      </p>
      <p>
        If you receive a refund, the cost of return shipping will be deducted
        from your refund.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about our refund policy, please contact us:
      </p>
      <p>Email: infi.boutique1205@gmail.com</p>
      <p>Phone: 9894407931</p>
    </div>
  );
};

export default RefundPolicy;
