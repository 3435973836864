// import React, { useState, useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";
// const BASE_URL = "https://infiboutique.in/api/api/";

// const Payment = () => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const { items } = location.state || { items: [] };

//   const [formData, setFormData] = useState({
//     firstName: "",
//     streetAddress: "",
//     city: "",
//     state: "",
//     country: "",
//     pinCode: "",
//     phone: "",
//   });

//   const [billingData, setBillingData] = useState({
//     name: "",
//     street: "",
//     city: "",
//     state: "",
//     country: "",
//     postalCode: "",
//     phone: "",
//   });

//   const [useShippingAddress, setUseShippingAddress] = useState(true);

//   const handleDeliveryChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleBillingChange = (e) => {
//     const { name, value } = e.target;
//     setBillingData({
//       ...billingData,
//       [name]: value,
//     });
//   };

//   const handleAddressOptionChange = (option) => {
//     setUseShippingAddress(option === "same");
//   };

//   const [couponCode, setCouponCode] = useState("");
//   const [discount, setDiscount] = useState(0);
//   const [couponError, setCouponError] = useState("");
//   const [isLoading, setIsLoading] = useState(true);
//   const userId = localStorage.getItem("userId");

//   const [deliveryCharges, setDeliveryCharges] = useState(0);

//   useEffect(() => {
//     const fetchUserAddress = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}user-address/${userId}/`);
//         const address = response.data;

//         if (address) {
//           setFormData({
//             firstName: address.name || "",
//             country: address.country || "",
//             streetAddress: address.street || "",
//             city: address.city || "",
//             state: address.state || "",
//             pinCode: address.postal_code || "",
//             phone: address.phone || "",
//           });
//         }
//       } catch (error) {
//         console.error("Error fetching user address:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchUserAddress();
//   }, [userId]);

//   const handleStateChange = (e) => {
//     const stateValue = e.target.value;
//     setFormData({ ...formData, state: stateValue });

//     if (stateValue.trim() !== "") {
//       setDeliveryCharges(calculateDeliveryCharge(stateValue));
//     } else {
//       setDeliveryCharges(0); // Reset delivery charge if state field is empty
//     }
//   };
//   const calculateDeliveryCharge = (state) => {
//     return state.toLowerCase() === "tamil nadu" ||
//       state.toLowerCase() === "tn" ||
//       state.toLowerCase() === "tamilnadu"
//       ? 65
//       : 120;
//   };

//   const deliveryCharge = calculateDeliveryCharge(formData.state);

//   const calculateSubtotal = (items) => {
//     return items.reduce((acc, product) => acc + product.item_price, 0);
//   };

//   const subtotal = calculateSubtotal(items);

//   // const total = subtotal + deliveryCharge;
//   const total = subtotal + (formData.state.trim() !== "" ? deliveryCharge : 0);

//   const discountedTotal = total - discount; // Apply discount to the total
//   // const totalSaved = product.discount_percentage - product.price;

//   const cart_item_ids = items.map((item) => item.cart_item_id);

//   const applyCoupon = async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}coupen/`);
//       const coupons = response.data;

//       // Find the coupon that matches the entered code
//       const validCoupon = coupons.find(
//         (coupon) => coupon.coupen_code === couponCode
//       );

//       if (validCoupon) {
//         // Check if the coupon's actual price is greater than or equal to the subtotal
//         if (validCoupon.actual_price <= subtotal) {
//           setDiscount(validCoupon.discount_price);
//           setCouponError(""); // Clear any previous error
//         } else {
//           // Show an error message if the coupon can't be applied
//           setCouponError(
//             "Coupon can only be applied if its value is greater than or equal to the cart subtotal."
//           );
//           setDiscount(0); // Don't apply any discount
//         }
//       } else {
//         setDiscount(0);
//         setCouponError("Invalid coupon code");
//       }
//     } catch (error) {
//       console.error("Error fetching coupon:", error);
//       setCouponError("There was an issue with applying the coupon");
//     }
//   };

//   const handlePayment = async (event) => {
//     event.preventDefault(); // Prevent default form submission

//     const orderData = {
//       user_id: userId, // Ensure userId is set correctly
//       cart_items: items.map((item) => ({
//         cart_item_id: item.cart_item_id, // Ensure cart_item_id is correctly referenced
//         item_price: item.item_price, // This should match the API requirements
//       })),
//       name: formData.firstName,
//       street: formData.streetAddress,
//       city: formData.city,
//       state: formData.state,
//       postal_code: formData.pinCode,
//       country: formData.country,
//       mobile: formData.phone,
//       total_amount: discountedTotal, // Total amount after applying the discount
//     };

//     try {
//       const response = await axios.post(`${BASE_URL}place-order/`, orderData);

//       const { razorpay_order_id, amount, currency } = response.data;

//       // Check if razorpay_order_id is valid
//       if (!razorpay_order_id) {
//         Swal.fire({
//           icon: "error",
//           title: "Order Error",
//           text: "Unable to create the order. Please try again.",
//         });
//         return;
//       }

//       // Set up Razorpay options
//       const options = {
//         key: "rzp_live_nuZKcLrhuStOWs", // Replace with your Razorpay key
//         amount: amount, // Amount in paise
//         currency: currency,
//         name: "Infiboutique",
//         description: "Order Payment",
//         order_id: razorpay_order_id,
//         handler: async (response) => {
//           const paymentResponse = {
//             razorpay_order_id: razorpay_order_id,
//             razorpay_payment_id: response.razorpay_payment_id,
//             razorpay_signature: response.razorpay_signature,
//           };
//           await confirmPayment(razorpay_order_id, paymentResponse);
//         },
//         prefill: {
//           name: formData.firstName,
//           email: "", // Add user's email if available
//           contact: formData.phone,
//         },
//         notes: {
//           address: `${formData.streetAddress}, ${formData.city}, ${formData.state}, ${formData.pinCode}, ${formData.country}`,
//         },
//         theme: {
//           color: "#F37254",
//         },
//       };

//       const rzp = new window.Razorpay(options);
//       rzp.open();
//     } catch (error) {
//       console.error(
//         "Error creating order:",
//         error.response ? error.response.data : error.message
//       );
//       const errorMessage = error.response
//         ? error.response.data.message ||
//           "There was an issue creating your order."
//         : "Network error. Please try again.";

//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: errorMessage,
//       });
//     }
//   };

//   const confirmPayment = async (orderId, paymentResponse) => {
//     const paymentData = {
//       razorpay_payment_id: paymentResponse.razorpay_payment_id,
//       razorpay_order_id: orderId,
//       razorpay_signature: paymentResponse.razorpay_signature,
//       user_id: localStorage.getItem("userId"),
//       order_amount: discountedTotal, // Ensure this is dynamically set based on your actual total
//       name: formData.firstName,
//       street: formData.streetAddress,
//       city: formData.city,
//       state: formData.state,
//       postal_code: formData.pinCode,
//       country: formData.country,
//       mobile: formData.phone,
//       cart_item_ids: cart_item_ids,
//       bill_name: useShippingAddress ? formData.firstName : billingData.name, // Use billing data if separate billing address is provided
//       bill_street: useShippingAddress
//         ? formData.streetAddress
//         : billingData.street,
//       bill_city: useShippingAddress ? formData.city : billingData.city,
//       bill_state: useShippingAddress ? formData.state : billingData.state,
//       bill_country: useShippingAddress ? formData.country : billingData.country,
//       bill_postal_code: useShippingAddress
//         ? formData.pinCode
//         : billingData.postalCode,
//       bill_mobile: useShippingAddress ? formData.phone : billingData.phone,
//     };

//     try {
//       await axios.post(`${BASE_URL}place-order-after-payment/`, paymentData);
//       const savingsText =
//         totalSavedAllProducts > 0
//           ? `Total amount you saved: ₹ ${totalSavedAllProducts.toLocaleString(
//               "en-IN",
//               { minimumFractionDigits: 2 }
//             )} INR`
//           : "";
//       Swal.fire({
//         icon: "success",
//         title: "Order Placed",
//         text: `Your order has been successfully placed! ${savingsText}`,
//         confirmButtonText: "OK",
//       }).then(() => {
//         // Redirect to home page when "OK" is clicked
//         window.location.href = "/"; // Change this to the correct home route if needed
//       });
//     } catch (error) {
//       console.error("Error confirming payment:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: "There was an issue confirming your payment. Please try again.",
//       });
//     }
//   };

//   const totalSavedAllProducts =
//     items.reduce((acc, product) => {
//       const productSaved = (product.price * product.discount_percentage) / 100;
//       return acc + productSaved * product.quantity; // Multiply by quantity
//     }, 0) + discount; // Add the discount (if any)

//   return (
//     <div>
//       <h2 className="text-center my-5">Payment</h2>
//       <div className="checkout_section" id="accordion">
//         <div className="container">
//           <div className="checkout_form">
//             <div className="row">
//               <div className="col-lg-5 col-md-6">
//                 {/* Delivery Address Form */}
//                 <form action="#">
//                   <h3>Delivery Address</h3>
//                   <div className="checkout_form_input">
//                     <label>Name *</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       name="firstName"
//                       value={formData.firstName}
//                       onChange={handleDeliveryChange}
//                       placeholder="Enter your name"
//                     />
//                   </div>

//                   <div className="checkout_form_input">
//                     <label>Street address *</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       name="streetAddress"
//                       value={formData.streetAddress}
//                       onChange={handleDeliveryChange}
//                       placeholder="Enter your street address"
//                     />
//                   </div>

//                   <div className="d-flex flex-column flex-md-row">
//                     <div className="checkout_form_input col-12 col-md-4 me-md-1">
//                       <label>Town / City *</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         name="city"
//                         value={formData.city}
//                         onChange={handleDeliveryChange}
//                         placeholder="Enter your city"
//                       />
//                     </div>
//                     <div className="checkout_form_input col-12 col-md-4 me-md-1">
//                       <label>State *</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         name="state"
//                         value={formData.state}
//                         onChange={handleDeliveryChange}
//                         placeholder="Enter your state"
//                       />
//                     </div>
//                     <div className="checkout_form_input col-12 col-md-4 ">
//                       <label>Country / Region *</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         name="country"
//                         value={formData.country}
//                         onChange={handleDeliveryChange}
//                         placeholder="Enter your country"
//                       />
//                     </div>
//                   </div>

//                   <div className="d-flex flex-column flex-md-row">
//                     <div className="checkout_form_input col-12 col-md-6 me-md-1">
//                       <label>PIN Code *</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         name="pinCode"
//                         value={formData.pinCode}
//                         onChange={handleDeliveryChange}
//                         placeholder="Enter your postal code"
//                       />
//                     </div>

//                     <div className="checkout_form_input col-12 col-md-6 ">
//                       <label>Phone *</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         name="phone"
//                         value={formData.phone}
//                         onChange={handleDeliveryChange}
//                         placeholder="Enter your phone number"
//                       />
//                     </div>
//                   </div>
//                 </form>

//                 {/* Option to use different billing address */}
//                 <div className="mb-3">
//                   <div className="form-check">
//                     <input
//                       type="radio"
//                       className="form-check-input"
//                       id="sameAddress"
//                       name="addressOption"
//                       checked={useShippingAddress}
//                       onChange={() => handleAddressOptionChange("same")}
//                     />
//                     <label className="form-check-label" htmlFor="sameAddress">
//                       Same as shipping address
//                     </label>
//                   </div>

//                   <div className="form-check">
//                     <input
//                       type="radio"
//                       className="form-check-input"
//                       id="differentAddress"
//                       name="addressOption"
//                       checked={!useShippingAddress}
//                       onChange={() => handleAddressOptionChange("different")}
//                     />
//                     <label
//                       className="form-check-label"
//                       htmlFor="differentAddress"
//                     >
//                       Use a different billing address
//                     </label>
//                   </div>
//                 </div>

//                 {/* Conditionally render the billing address form */}
//                 {!useShippingAddress && (
//                   <form action="#">
//                     <h3>Billing Address</h3>
//                     <div className="checkout_form_input">
//                       <label>Billing Name *</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         name="name"
//                         value={billingData.name}
//                         onChange={handleBillingChange}
//                         placeholder="Enter billing name"
//                       />
//                     </div>

//                     <div className="checkout_form_input">
//                       <label>Billing Street Address *</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         name="street"
//                         value={billingData.street}
//                         onChange={handleBillingChange}
//                         placeholder="Enter billing street address"
//                       />
//                     </div>

//                     <div className="d-flex flex-column flex-md-row">
//                       <div className="checkout_form_input col-12 col-md-4 me-md-1">
//                         <label>Billing Town / City *</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           name="city"
//                           value={billingData.city}
//                           onChange={handleBillingChange}
//                           placeholder="Enter billing city"
//                         />
//                       </div>

//                       <div className="checkout_form_input col-12 col-md-4 me-md-1">
//                         <label>Billing State *</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           name="state"
//                           value={billingData.state}
//                           onChange={handleBillingChange}
//                           placeholder="Enter billing state"
//                         />
//                       </div>

//                       <div className="checkout_form_input col-12 col-md-4">
//                         <label>Billing Country / Region *</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           name="country"
//                           value={billingData.country}
//                           onChange={handleBillingChange}
//                           placeholder="Enter billing country"
//                         />
//                       </div>
//                     </div>

//                     <div className="d-flex flex-column flex-md-row">
//                       <div className="checkout_form_input col-12 col-md-6 me-md-1">
//                         <label>Billing PIN Code *</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           name="postalCode"
//                           value={billingData.postalCode}
//                           onChange={handleBillingChange}
//                           placeholder="Enter billing postal code"
//                         />
//                       </div>

//                       <div className="checkout_form_input col-12 col-md-6">
//                         <label>Billing Phone *</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           name="phone"
//                           value={billingData.phone}
//                           onChange={handleBillingChange}
//                           placeholder="Enter billing phone number"
//                         />
//                       </div>
//                     </div>
//                   </form>
//                 )}
//               </div>

//               <div className="col-lg-7 col-md-6">
//                 <div className="order_table_right  ">
//                   <form action="#">
//                     <h3>Your order</h3>
//                     <div className="order_table table-responsive">
//                       <table>
//                         <thead>
//                           <tr>
//                             <th>Image</th>
//                             <th>Product</th>
//                             <th>quantity</th>
//                             <th>Size</th>

//                             <th>Price</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {items.map((product) => (
//                             <tr key={product.id}>
//                               <td>
//                                 <div className="paymet_img ">
//                                   <img
//                                     src={product.images[0]}
//                                     alt={product.product_name}
//                                   />
//                                 </div>
//                               </td>
//                               <td>{product.product_name}</td>
//                               <td>{product.quantity}</td>
//                               <td>{product.selected_options.size}</td>

//                               <td>
//                                 ₹
//                                 {parseFloat(product.item_price).toLocaleString(
//                                   "en-IN",
//                                   {
//                                     minimumFractionDigits: 2,
//                                   }
//                                 )}{" "}
//                                 INR
//                               </td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </div>

//                     {isLoading ? (
//                       <p>Loading address...</p>
//                     ) : (
//                       <form action="#">
//                         <div class="user-actions ">
//                           {/* <h3>
//                             <i class="fa fa-comment-o" aria-hidden="true"></i>
//                             Have a Coupon ?

//                           </h3> */}
//                           <div class="checkout_info coupon_info order_table p-4">
//                             <form action="#">
//                               <input
//                                 type="text"
//                                 className="form-control bg-white"
//                                 value={couponCode}
//                                 onChange={(e) => setCouponCode(e.target.value)}
//                                 placeholder="Enter coupon code"
//                               />{" "}
//                               <button
//                                 type="button"
//                                 className="btn btn-primary"
//                                 onClick={applyCoupon}
//                               >
//                                 Apply Coupon
//                               </button>
//                             </form>
//                             {couponError && (
//                               <p className="text-danger text-center">
//                                 {couponError}
//                               </p>
//                             )}
//                           </div>

//                         </div>
//                       </form>
//                     )}
//                     <div className="order_table_right">
//                       <form action="#">
//                         <div className="order_table table-responsive">
//                           <table>
//                             <tfoot>
//                               <tr>
//                                 <td>Cart Subtotal </td>
//                                 <td className="text-right">
//                                   ₹
//                                   {parseFloat(subtotal).toLocaleString(
//                                     "en-IN",
//                                     { minimumFractionDigits: 2 }
//                                   )}{" "}
//                                   INR
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <td>Delivery Charge</td>
//                                 {formData.state.trim() === "" ? (
//                                   <td className="text-right">
//                                     Enter shipping address
//                                   </td>
//                                 ) : (
//                                   <td className="text-right">
//                                     ₹
//                                     {parseFloat(deliveryCharge).toLocaleString(
//                                       "en-IN",
//                                       {
//                                         minimumFractionDigits: 2,
//                                       }
//                                     )}{" "}
//                                     INR
//                                   </td>
//                                 )}
//                               </tr>

//                               {discount > 0 && (
//                                 <tr>
//                                   <td>Coupon Discount</td>
//                                   <td className="text-right">
//                                     -₹
//                                     {Math.abs(
//                                       parseFloat(discount)
//                                     ).toLocaleString("en-IN", {
//                                       minimumFractionDigits: 2,
//                                     })}{" "}
//                                     INR
//                                   </td>
//                                 </tr>
//                               )}
//                               <tr className="order_total">
//                                 <th>Order Total</th>
//                                 <td className="text-right">
//                                   ₹
//                                   {parseFloat(discountedTotal).toLocaleString(
//                                     "en-IN",
//                                     { minimumFractionDigits: 2 }
//                                   )}{" "}
//                                   INR
//                                 </td>
//                               </tr>
//                               {totalSavedAllProducts > 0 && (
//                                 <tr className="order_total">
//                                   <th>Total amount you saved</th>
//                                   <td
//                                     className="text-right "
//                                     style={{ color: "green" }}
//                                   >
//                                     ₹
//                                     {totalSavedAllProducts.toLocaleString(
//                                       "en-IN",
//                                       {
//                                         minimumFractionDigits: 2,
//                                       }
//                                     )}{" "}
//                                     INR
//                                   </td>
//                                 </tr>
//                               )}
//                             </tfoot>
//                           </table>
//                         </div>
//                       </form>
//                     </div>
//                     <div className="place_order_btn">
//                       <Link
//                         className="btn btn-success"
//                         to="#"
//                         onClick={handlePayment}
//                         disabled={isLoading}
//                       >
//                         place order
//                       </Link>
//                     </div>
//                     <Link to="#" onClick={() => navigate(-1)}>
//                       <button className="btn btn-primary mt-4">Go Back</button>
//                     </Link>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Payment;

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const BASE_URL = "https://infiboutique.in/api/api/";

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errors, setErrors] = useState({});

  const { items } = location.state || { items: [] };

  const [formData, setFormData] = useState({
    firstName: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    phone: "",
  });

  const [billingData, setBillingData] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    phone: "",
  });

  const [useShippingAddress, setUseShippingAddress] = useState(true);

  const handleDeliveryChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBillingChange = (e) => {
    const { name, value } = e.target;
    setBillingData({
      ...billingData,
      [name]: value,
    });
  };

  const handleAddressOptionChange = (option) => {
    setUseShippingAddress(option === "same");
  };
  const validateForm = () => {
    const newErrors = {};

    // Check delivery address fields
    if (!formData.firstName) newErrors.firstName = "Name is required";
    if (!formData.streetAddress)
      newErrors.streetAddress = "Street address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.pinCode) newErrors.pinCode = "PIN code is required";
    if (!formData.phone) newErrors.phone = "Phone number is required";

    // Check billing address fields if a different billing address is used
    if (!useShippingAddress) {
      if (!billingData.name) newErrors.billingName = "Billing name is required";
      if (!billingData.street)
        newErrors.billingStreet = "Billing street address is required";
      if (!billingData.city) newErrors.billingCity = "Billing city is required";
      if (!billingData.state)
        newErrors.billingState = "Billing state is required";
      if (!billingData.country)
        newErrors.billingCountry = "Billing country is required";
      if (!billingData.postalCode)
        newErrors.billingPostalCode = "Billing PIN code is required";
      if (!billingData.phone)
        newErrors.billingPhone = "Billing phone number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [couponError, setCouponError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const userId = localStorage.getItem("userId");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState(0);

  useEffect(() => {
    const fetchUserAddress = async () => {
      try {
        const response = await axios.get(`${BASE_URL}user-address/${userId}/`);
        const address = response.data;

        if (address) {
          setFormData({
            firstName: address.name || "",
            country: address.country || "",
            streetAddress: address.street || "",
            city: address.city || "",
            state: address.state || "",
            pinCode: address.postal_code || "",
            phone: address.phone || "",
          });
        }
      } catch (error) {
        console.error("Error fetching user address:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserAddress();
  }, [userId]);

  const [filteredStates, setFilteredStates] = useState([]);

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];

  const handleStateChange = (e) => {
    const stateValue = e.target.value;
    setFormData({ ...formData, state: stateValue });
    setShowSuggestions(true);

    if (stateValue.trim() !== "") {
      setFilteredStates(
        states.filter((state) =>
          state.toLowerCase().includes(stateValue.toLowerCase())
        )
      );
      setDeliveryCharges(calculateDeliveryCharge(stateValue));
    } else {
      setFilteredStates(states);
      setDeliveryCharges(0); // Reset delivery charge if state field is empty
    }
  };

  const calculateDeliveryCharge = (state) => {
    return state.toLowerCase() === "tamil nadu" ||
      state.toLowerCase() === "tn" ||
      state.toLowerCase() === "tamilnadu" ||
      state.toLowerCase() === "tamilnadu " ||
      state.toLowerCase() === " tamilnadu " ||
      state.toLowerCase() === " tamilnadu"
      ? 65
      : 120;
  };

  const selectState = (state) => {
    setFormData({ ...formData, state });
    setShowSuggestions(false);
    setDeliveryCharges(calculateDeliveryCharge(state));
  };
  const deliveryCharge = calculateDeliveryCharge(formData.state);

  const calculateSubtotal = (items) => {
    return items.reduce((acc, product) => acc + product.item_price, 0);
  };

  const subtotal = calculateSubtotal(items);

  // const total = subtotal + deliveryCharge;
  const total = subtotal + (formData.state.trim() !== "" ? deliveryCharge : 0);

  const discountedTotal = total - discount; // Apply discount to the total
  // const totalSaved = product.discount_percentage - product.price;

  const cart_item_ids = items.map((item) => item.cart_item_id);

  const applyCoupon = async () => {
    try {
      const response = await axios.get(`${BASE_URL}coupen/`);
      const coupons = response.data;

      // Find the coupon that matches the entered code
      const validCoupon = coupons.find(
        (coupon) => coupon.coupen_code === couponCode
      );

      if (validCoupon) {
        // Check if the coupon's actual price is greater than or equal to the subtotal
        if (validCoupon.actual_price <= subtotal) {
          setDiscount(validCoupon.discount_price);
          setCouponError(""); // Clear any previous error
        } else {
          // Show an error message if the coupon can't be applied
          setCouponError(
            "Coupon can only be applied if its value is greater than or equal to the cart subtotal."
          );
          setDiscount(0); // Don't apply any discount
        }
      } else {
        setDiscount(0);
        setCouponError("Invalid coupon code");
      }
    } catch (error) {
      console.error("Error fetching coupon:", error);
      setCouponError("There was an issue with applying the coupon");
    }
  };

  const handlePayment = async (event) => {
    event.preventDefault(); // Prevent default form submission
    if (!validateForm()) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please fill in all required fields.",
      });
      return;
    }
    const orderData = {
      user_id: userId, // Ensure userId is set correctly
      cart_items: items.map((item) => ({
        cart_item_id: item.cart_item_id, // Ensure cart_item_id is correctly referenced
        item_price: item.item_price, // This should match the API requirements
      })),
      name: formData.firstName,
      street: formData.streetAddress,
      city: formData.city,
      state: formData.state,
      postal_code: formData.pinCode,
      country: formData.country,
      mobile: formData.phone,
      total_amount: discountedTotal, // Total amount after applying the discount
    };

    try {
      const response = await axios.post(`${BASE_URL}place-order/`, orderData);

      const { razorpay_order_id, amount, currency } = response.data;

      // Check if razorpay_order_id is valid
      if (!razorpay_order_id) {
        Swal.fire({
          icon: "error",
          title: "Order Error",
          text: "Unable to create the order. Please try again.",
        });
        return;
      }

      // Set up Razorpay options
      const options = {
        key: "rzp_live_nuZKcLrhuStOWs", // Replace with your Razorpay key
        amount: amount, // Amount in paise
        currency: currency,
        name: "Infiboutique",
        description: "Order Payment",
        order_id: razorpay_order_id,
        handler: async (response) => {
          const paymentResponse = {
            razorpay_order_id: razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };
          await confirmPayment(razorpay_order_id, paymentResponse);
        },
        prefill: {
          name: formData.firstName,
          email: "", // Add user's email if available
          contact: formData.phone,
        },
        notes: {
          address: `${formData.streetAddress}, ${formData.city}, ${formData.state}, ${formData.pinCode}, ${formData.country}`,
        },
        theme: {
          color: "#F37254",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error(
        "Error creating order:",
        error.response ? error.response.data : error.message
      );
      const errorMessage = error.response
        ? error.response.data.message ||
          "There was an issue creating your order."
        : "Network error. Please try again.";

      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
      });
    }
  };

  const confirmPayment = async (orderId, paymentResponse) => {
    const paymentData = {
      razorpay_payment_id: paymentResponse.razorpay_payment_id,
      razorpay_order_id: orderId,
      razorpay_signature: paymentResponse.razorpay_signature,
      user_id: localStorage.getItem("userId"),
      order_amount: discountedTotal, // Ensure this is dynamically set based on your actual total
      name: formData.firstName,
      street: formData.streetAddress,
      city: formData.city,
      state: formData.state,
      postal_code: formData.pinCode,
      country: formData.country,
      mobile: formData.phone,
      cart_item_ids: cart_item_ids,
      bill_name: useShippingAddress ? formData.firstName : billingData.name, // Use billing data if separate billing address is provided
      bill_street: useShippingAddress
        ? formData.streetAddress
        : billingData.street,
      bill_city: useShippingAddress ? formData.city : billingData.city,
      bill_state: useShippingAddress ? formData.state : billingData.state,
      bill_country: useShippingAddress ? formData.country : billingData.country,
      bill_postal_code: useShippingAddress
        ? formData.pinCode
        : billingData.postalCode,
      bill_mobile: useShippingAddress ? formData.phone : billingData.phone,
    };

    try {
      await axios.post(`${BASE_URL}place-order-after-payment/`, paymentData);
      const savingsText =
        totalSavedAllProducts > 0
          ? `Total amount you saved: ₹ ${totalSavedAllProducts.toLocaleString(
              "en-IN",
              { minimumFractionDigits: 2 }
            )} INR`
          : "";
      Swal.fire({
        icon: "success",
        title: "Order Placed",
        text: `Your order has been successfully placed! ${savingsText}`,
        confirmButtonText: "OK",
      }).then(() => {
        // Redirect to home page when "OK" is clicked
        window.location.href = "/"; // Change this to the correct home route if needed
      });
    } catch (error) {
      console.error("Error confirming payment:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue confirming your payment. Please try again.",
      });
    }
  };

  const totalSavedAllProducts =
    items.reduce((acc, product) => {
      const productSaved = (product.price * product.discount_percentage) / 100;
      return acc + productSaved * product.quantity; // Multiply by quantity
    }, 0) + discount; // Add the discount (if any)

  return (
    <div>
      <h2 className="text-center my-5">Payment</h2>
      <div className="checkout_section" id="accordion">
        <div className="container">
          <div className="checkout_form">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                {/* Delivery Address Form */}
                <form action="#">
                  <h3>Delivery Address</h3>
                  <div className="checkout_form_input">
                    <label>Name *</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.firstName ? " input-error border-danger" : ""
                      }`}
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleDeliveryChange}
                      placeholder="Enter your name"
                    />
                  </div>

                  <div className="checkout_form_input">
                    <label>Street address *</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.streetAddress ? "input-error border-danger" : ""
                      }`}
                      name="streetAddress"
                      value={formData.streetAddress}
                      onChange={handleDeliveryChange}
                      placeholder="Enter your street address"
                    />
                  </div>

                  <div className="d-flex flex-column flex-md-row">
                    <div className="checkout_form_input col-12 col-md-4 me-md-1">
                      <label>Town / City *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.city ? "input-error border-danger" : ""
                        }`}
                        name="city"
                        value={formData.city}
                        onChange={handleDeliveryChange}
                        placeholder="Enter your city"
                      />
                    </div>
                    {/* <div className="checkout_form_input col-12 col-md-4 me-md-1">
                      <label>State *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.state ? "input-error border-danger" : ""
                        }`}
                        name="state"
                        value={formData.state}
                        onChange={handleDeliveryChange}
                        placeholder="Enter your state"
                      />
                    </div> */}
                    <div className="checkout_form_input col-12 col-md-4 me-md-1">
                      <label>State *</label>
                      <input
                        type="text"
                        className={`form-control`}
                        name="state"
                        value={formData.state}
                        onChange={handleStateChange}
                        onFocus={() => setShowSuggestions(true)}
                        placeholder="Enter your state"
                      />
                      {showSuggestions && (
                        <ul className="suggestions-list">
                          {filteredStates.length > 0 ? (
                            filteredStates.map((state, index) => (
                              <li
                                key={index}
                                onClick={() => selectState(state)}
                                className="suggestion-item"
                              >
                                {state}
                              </li>
                            ))
                          ) : (
                            <li className="no-suggestions">
                              No suggestions found
                            </li>
                          )}
                        </ul>
                      )}
                    </div>

                    <div className="checkout_form_input col-12 col-md-4 ">
                      <label>Country / Region *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.country ? "input-error border-danger" : ""
                        }`}
                        name="country"
                        value={formData.country}
                        onChange={handleDeliveryChange}
                        placeholder="Enter your country"
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-column flex-md-row">
                    <div className="checkout_form_input col-12 col-md-6 me-md-1">
                      <label>PIN Code *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.pinCode ? "input-error border-danger" : ""
                        }`}
                        name="pinCode"
                        value={formData.pinCode}
                        onChange={handleDeliveryChange}
                        placeholder="Enter your postal code"
                      />
                    </div>

                    <div className="checkout_form_input col-12 col-md-6 ">
                      <label>Phone *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.phone ? "input-error border-danger" : ""
                        }`}
                        name="phone"
                        value={formData.phone}
                        onChange={handleDeliveryChange}
                        placeholder="Enter your phone number"
                      />
                    </div>
                  </div>
                </form>

                <div className="mb-3">
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="sameAddress"
                      name="addressOption"
                      checked={useShippingAddress}
                      onChange={() => handleAddressOptionChange("same")}
                    />
                    <label className="form-check-label" htmlFor="sameAddress">
                      Same as shipping address
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="differentAddress"
                      name="addressOption"
                      checked={!useShippingAddress}
                      onChange={() => handleAddressOptionChange("different")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="differentAddress"
                    >
                      Use a different billing address
                    </label>
                  </div>
                </div>

                {/* Conditionally render the billing address form */}
                {!useShippingAddress && (
                  <form action="#">
                    <h3>Billing Address</h3>
                    <div className="checkout_form_input">
                      <label>Billing Name *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.billingName ? "input-error border-danger" : ""
                        }`}
                        name="name"
                        value={billingData.name}
                        onChange={handleBillingChange}
                        placeholder="Enter billing name"
                      />
                    </div>

                    <div className="checkout_form_input">
                      <label>Billing Street Address *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.billingStreet
                            ? "input-error border-danger"
                            : ""
                        }`}
                        name="street"
                        value={billingData.street}
                        onChange={handleBillingChange}
                        placeholder="Enter billing street address"
                      />
                    </div>

                    <div className="d-flex flex-column flex-md-row">
                      <div className="checkout_form_input col-12 col-md-4 me-md-1">
                        <label>Billing Town / City *</label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.billingCity
                              ? "input-error border-danger"
                              : ""
                          }`}
                          name="city"
                          value={billingData.city}
                          onChange={handleBillingChange}
                          placeholder="Enter billing city"
                        />
                      </div>

                      <div className="checkout_form_input col-12 col-md-4 me-md-1">
                        <label>Billing State *</label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.billingState
                              ? "input-error border-danger"
                              : ""
                          }`}
                          name="state"
                          value={billingData.state}
                          onChange={handleBillingChange}
                          placeholder="Enter billing state"
                        />
                      </div>

                      <div className="checkout_form_input col-12 col-md-4">
                        <label>Billing Country / Region *</label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.billingCountry
                              ? "input-error border-danger"
                              : ""
                          }`}
                          name="country"
                          value={billingData.country}
                          onChange={handleBillingChange}
                          placeholder="Enter billing country"
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column flex-md-row">
                      <div className="checkout_form_input col-12 col-md-6 me-md-1">
                        <label>Billing PIN Code *</label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.billingPostalCode
                              ? "input-error border-danger"
                              : ""
                          }`}
                          name="postalCode"
                          value={billingData.postalCode}
                          onChange={handleBillingChange}
                          placeholder="Enter billing postal code"
                        />
                      </div>

                      <div className="checkout_form_input col-12 col-md-6">
                        <label>Billing Phone *</label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.billingPhone
                              ? "input-error border-danger"
                              : ""
                          }`}
                          name="phone"
                          value={billingData.phone}
                          onChange={handleBillingChange}
                          placeholder="Enter billing phone number"
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>

              <div className="col-lg-7 col-md-6">
                <div className="order_table_right  ">
                  <form action="#">
                    <h3>Your order</h3>
                    <div className="order_table table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Product</th>
                            <th>quantity</th>
                            <th>Size</th>

                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map((product) => (
                            <tr key={product.id}>
                              <td>
                                <div className="paymet_img ">
                                  <img
                                    src={product.images[0]}
                                    alt={product.product_name}
                                  />
                                </div>
                              </td>
                              <td>{product.product_name}</td>
                              <td>{product.quantity}</td>
                              <td>{product.selected_options.size}</td>

                              <td>
                                ₹
                                {parseFloat(product.item_price).toLocaleString(
                                  "en-IN",
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )}{" "}
                                INR
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {isLoading ? (
                      <p>Loading address...</p>
                    ) : (
                      <form action="#">
                        <div class="user-actions ">
                          {/* <h3>
                            <i class="fa fa-comment-o" aria-hidden="true"></i>
                            Have a Coupon ?
                            
                          </h3> */}
                          <div class="checkout_info coupon_info order_table p-4">
                            <form action="#">
                              <input
                                type="text"
                                className="form-control bg-white"
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                                placeholder="Enter coupon code"
                              />{" "}
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={applyCoupon}
                              >
                                Apply Coupon
                              </button>
                            </form>
                            {couponError && (
                              <p className="text-danger text-center">
                                {couponError}
                              </p>
                            )}
                          </div>
                        </div>
                      </form>
                    )}
                    <div className="order_table_right">
                      <form action="#">
                        <div className="order_table table-responsive">
                          <table>
                            <tfoot>
                              <tr>
                                <td>Cart Subtotal </td>
                                <td className="text-right">
                                  ₹
                                  {parseFloat(subtotal).toLocaleString(
                                    "en-IN",
                                    { minimumFractionDigits: 2 }
                                  )}{" "}
                                  INR
                                </td>
                              </tr>

                              <tr>
                                <td>Delivery Charge</td>
                                {formData.state.trim() === "" ? (
                                  <td className="text-right">
                                    Enter shipping address
                                  </td>
                                ) : (
                                  <td className="text-right">
                                    ₹
                                    {parseFloat(deliveryCharge).toLocaleString(
                                      "en-IN",
                                      {
                                        minimumFractionDigits: 2,
                                      }
                                    )}{" "}
                                    INR
                                  </td>
                                )}
                              </tr>

                              {discount > 0 && (
                                <tr>
                                  <td>Coupon Discount</td>
                                  <td className="text-right">
                                    -₹
                                    {Math.abs(
                                      parseFloat(discount)
                                    ).toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                    })}{" "}
                                    INR
                                  </td>
                                </tr>
                              )}
                              <tr className="order_total">
                                <th>Order Total</th>
                                <td className="text-right">
                                  ₹
                                  {parseFloat(discountedTotal).toLocaleString(
                                    "en-IN",
                                    { minimumFractionDigits: 2 }
                                  )}{" "}
                                  INR
                                </td>
                              </tr>
                              {totalSavedAllProducts > 0 && (
                                <tr className="order_total">
                                  <th>Total amount you saved</th>
                                  <td
                                    className="text-right "
                                    style={{ color: "green" }}
                                  >
                                    ₹
                                    {totalSavedAllProducts.toLocaleString(
                                      "en-IN",
                                      {
                                        minimumFractionDigits: 2,
                                      }
                                    )}{" "}
                                    INR
                                  </td>
                                </tr>
                              )}
                            </tfoot>
                          </table>
                        </div>
                      </form>
                    </div>
                    <div className="place_order_btn">
                      <Link
                        className="btn btn-success"
                        to="#"
                        onClick={handlePayment}
                        disabled={isLoading}
                      >
                        place order
                      </Link>
                    </div>
                    <Link to="#" onClick={() => navigate(-1)}>
                      <button className="btn btn-primary mt-4">Go Back</button>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
