import React, { useEffect, useState } from "react";
import axios from "axios";
import { deleteCartItem } from "./Apiservice";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GoTrash } from "react-icons/go";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();
  const [availableStock, setAvailableStock] = useState({});
  const [showConfirmButton, setShowConfirmButton] = useState({});
  const [loadingUpdate, setLoadingUpdate] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  useEffect(() => {
    const fetchCartDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const userId = localStorage.getItem("userId");

        if (userId) {
          const response = await axios.get(
            `https://infiboutique.in/api/api/cart-fetch/${userId}/`
          );
          setCartItems(response.data.cart);

          // Optionally, create a map of available stock by size
          const availableStockMap = {};
          response.data.cart.forEach((item) => {
            item.stock_details.forEach((stock) => {
              availableStockMap[`${item.cart_item_id}-${stock.size}`] =
                stock.quantity;
            });
          });

          setAvailableStock(availableStockMap);
        } else {
          setError("User ID not found in local storage.");
        }
      } catch (err) {
        console.error(err);
        setError("No cart items.");
      } finally {
        setLoading(false);
      }
    };

    fetchCartDetails();
  }, []);

  const handleUpdateQuantity = (cartItemId, newQuantity, size) => {
    if (newQuantity < 1) return;

    const stockKey = `${cartItemId}-${size}`;
    const available = availableStock[stockKey];

    if (newQuantity > available) {
      Swal.fire({
        icon: "error",
        title: "Stock Limit Exceeded",
        text: `You can only add up to ${available} items to the cart.`,
      });
      return; // Don't proceed if the quantity exceeds available stock
    }

    // Update the cart item state with the new quantity
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.cart_item_id === cartItemId
          ? { ...item, quantity: newQuantity }
          : item
      )
    );

    // Show the confirm button after quantity change
    setShowConfirmButton((prev) => ({ ...prev, [cartItemId]: true }));
  };

  const handleConfirmUpdate = async (cartItemId, newQuantity) => {
    setLoadingUpdate((prev) => ({ ...prev, [cartItemId]: true })); // Show loading

    try {
      await axios.patch(
        `https://infiboutique.in/api/api/cart-update/${cartItemId}/`,
        { quantity: newQuantity }
      );

      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.cart_item_id === cartItemId
            ? { ...item, quantity: newQuantity }
            : item
        )
      );

      Swal.fire({
        icon: "success",
        title: "Updated",
        text: "Quantity and price updated successfully!",
        timer: 1500,
        showConfirmButton: false,
      }).then(() => {
        // Reload the page when "OK" is clicked
        window.location.reload();
      });

      // Hide the confirm button after success
      setShowConfirmButton((prev) => ({ ...prev, [cartItemId]: false }));
    } catch (error) {
      console.error("Error updating quantity", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update quantity.",
      });
    } finally {
      setLoadingUpdate((prev) => ({ ...prev, [cartItemId]: false })); // Reset loading
    }
  };

  const handleRemoveItem = async (cartItemId) => {
    try {
      await deleteCartItem(cartItemId);
      setCartItems((prevItems) =>
        prevItems.filter((item) => item.cart_item_id !== cartItemId)
      );
      Swal.fire({
        icon: "success",
        title: "Removed",
        text: "Item removed successfully from cart!",
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error("Error removing item from cart", error);
      setError("Failed to remove item from cart.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to remove item from cart.",
      });
    }
  };

  // Handle selecting an item
  const handleSelectItem = (cartItemId) => {
    setSelectedItems((prevSelected) => {
      const item = cartItems.find((item) => item.cart_item_id === cartItemId);

      if (item) {
        const selectedSize = item.selected_options.size;
        const stockDetail = item.stock_details.find(
          (stock) => stock.size === selectedSize
        );

        const hasSufficientQuantity =
          stockDetail && stockDetail.quantity >= item.quantity;

        if (hasSufficientQuantity) {
          return prevSelected.includes(cartItemId)
            ? prevSelected.filter((id) => id !== cartItemId)
            : [...prevSelected, cartItemId];
        } else {
          Swal.fire({
            icon: "warning",
            title: "Insufficient Stock",
            text: `The selected size (${selectedSize}) does not have enough stock.`,
          });
        }
      }
      return prevSelected;
    });
  };

  // Handle placing an order
  const handleOrderClick = () => {
    const itemsToOrder = cartItems
      .filter((item) => selectedItems.includes(item.cart_item_id))
      .map((item) => ({
        ...item,
        updated_price: parseFloat(item.item_price * item.quantity).toFixed(2),
      }));

    if (itemsToOrder.length > 0) {
      Swal.fire({
        icon: "success",
        title: "Proceeding to Order",
        text: "Redirecting to order page...",
        timer: 1500,
        showConfirmButton: false,
      }).then(() => {
        navigate("/Payment", { state: { items: itemsToOrder } });
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "No items selected",
        text: "Please select at least one item to order.",
      });
    }
  };

  return (
    <div>
      <h2 className="text-center my-5">Shopping Cart</h2>

      <div className="shopping_cart_area mt-5">
        <div className="container">
          <form action="#">
            <div className="cart_page_inner mb-60">
              <div className="row">
                <div className="col-12">
                  <div className="cart_page_tabel">
                    {loading ? (
                      <p>Loading...</p>
                    ) : error ? (
                      <p>{error}</p>
                    ) : (
                      <table>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th className="text-center">Information</th>
                            <th className="text-center">Quantity</th>
                            <th className="text-center">Total</th>
                            <th className="text-center">Select to order</th>
                            <th>Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItems.length > 0 ? (
                            [...cartItems].reverse().map((item) => (
                              <tr key={item.product_id} className="border-top">
                                <td>
                                  <div className="cart_product_thumb">
                                    <Link
                                      to={`/Productdetail/${item.product_id}`}
                                    >
                                      <img
                                        src={item.images[0]}
                                        alt={item.product_name}
                                      />
                                    </Link>
                                  </div>
                                </td>
                                <td className="cart-centermlist">
                                  <div className="cart_product_text">
                                    <h4>
                                      <Link
                                        to={`/Productdetail/${item.product_id}`}
                                        style={{ color: "black" }}
                                      >
                                        {item.product_name}{" "}
                                      </Link>
                                    </h4>

                                    <ul>
                                      <li>
                                        Size:{" "}
                                        <span>
                                          {item.selected_options.size}
                                        </span>
                                      </li>
                                      <li>
                                        Style: <span>{item.style}</span>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td className="product_quantity mx-2 ">
                                  <div className="cart_product_quantity d-flex align-items-center justify-content-center">
                                    <button
                                      className="btn btn-outline-secondary cart-border"
                                      type="button"
                                      onClick={() =>
                                        handleUpdateQuantity(
                                          item.cart_item_id,
                                          item.quantity - 1,
                                          item.selected_options.size
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                    <input
                                      className="text-center"
                                      min="1"
                                      value={item.quantity}
                                      type="number"
                                      readOnly
                                      style={{
                                        width: "50px",
                                      }}
                                    />

                                    <button
                                      className="btn btn-outline-secondary cart-borderplus"
                                      type="button"
                                      onClick={() =>
                                        handleUpdateQuantity(
                                          item.cart_item_id,
                                          item.quantity + 1,
                                          item.selected_options.size
                                        )
                                      }
                                    >
                                      +
                                    </button>

                                    {/* Show confirm button if quantity change is triggered */}
                                    {showConfirmButton[item.cart_item_id] &&
                                      !loadingUpdate[item.cart_item_id] && (
                                        <button
                                          className="btn btn-outline-success ml-2"
                                          type="button"
                                          onClick={() =>
                                            handleConfirmUpdate(
                                              item.cart_item_id,
                                              item.quantity
                                            )
                                          }
                                        >
                                          Update
                                        </button>
                                      )}

                                    {/* Show loading spinner if updating */}
                                    {loadingUpdate[item.cart_item_id] && (
                                      <span
                                        className="spinner-border spinner-border-sm ml-2"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div className="cart_product_price">
                                    {loadingUpdate[item.cart_item_id] ? (
                                      <span>
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            marginLeft: "5px",
                                          }}
                                        >
                                          Updating...
                                        </span>
                                      </span>
                                    ) : (
                                      <span>
                                        ₹
                                        {parseFloat(
                                          item.item_price
                                        ).toLocaleString("en-IN", {
                                          minimumFractionDigits: 2,
                                        })}{" "}
                                        <span style={{ fontSize: "13px" }}>
                                          INR
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                </td>

                                <td className="cart-center">
                                  <div className="checkboxes__row">
                                    <label className="checkbox style-h">
                                      <input
                                        type="checkbox"
                                        checked={selectedItems.includes(
                                          item.cart_item_id
                                        )}
                                        onChange={() =>
                                          handleSelectItem(item.cart_item_id)
                                        }
                                      />
                                      <div className="checkbox__checkmark"></div>
                                      <div className="checkbox__body">
                                        <span>Select to Order</span>
                                      </div>
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <div className="cart_product_remove">
                                    <button
                                      className="btn btn-danger px-4"
                                      type="button"
                                      onClick={() =>
                                        handleRemoveItem(item.cart_item_id)
                                      }
                                    >
                                      <GoTrash
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "900",
                                        }}
                                      />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">Your cart is empty.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="cart_page_mobiletabel">
                    {loading ? (
                      <p>Loading...</p>
                    ) : error ? (
                      <p>{error}</p>
                    ) : (
                      <div>
                        {/* Mobile Responsive Design */}
                        {cartItems.length > 0 ? (
                          [...cartItems].reverse().map((item) => (
                            <div
                              key={item.product_id}
                              className="cart_item_mobile"
                            >
                              <div className="cart_item_mobile_info">
                                <div className="cart_product_thumb">
                                  <Link
                                    to={`/Productdetail/${item.product_id}`}
                                  >
                                    <img
                                      src={item.images[0]}
                                      alt={item.product_name}
                                    />
                                  </Link>
                                </div>
                                <div>
                                  <div className="cart_item_mobile_price">
                                    <span>
                                      ₹
                                      {parseFloat(
                                        item.item_price
                                      ).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                      })}{" "}
                                      <span style={{ fontSize: "13px" }}>
                                        INR
                                      </span>
                                    </span>
                                  </div>
                                  <h4>
                                    {" "}
                                    <Link
                                      to={`/Productdetail/${item.product_id}`}
                                      style={{ color: "black" }}
                                    >
                                      {item.product_name}
                                    </Link>
                                  </h4>
                                  <ul style={{ paddingLeft: "0px" }}>
                                    <li>
                                      Size:{" "}
                                      <span>{item.selected_options.size}</span>
                                    </li>
                                    <li style={{ paddingLeft: "0px" }}>
                                      Style: <span>{item.style}</span>
                                    </li>
                                  </ul>
                                </div>
                                <div>
                                  <div className="cart_item_mobile_remove">
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() =>
                                        handleRemoveItem(item.cart_item_id)
                                      }
                                    >
                                      <GoTrash />
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="cart_item_mobile_actions">
                                <div>
                                  <div className="quantity">
                                    <button
                                      className="btn btn-outline-secondary cart-border"
                                      type="button"
                                      onClick={() =>
                                        handleUpdateQuantity(
                                          item.cart_item_id,
                                          item.quantity - 1,
                                          item.selected_options.size
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                    <input
                                      className="text-center"
                                      min="1"
                                      value={item.quantity}
                                      type="number"
                                      readOnly
                                    />
                                    <button
                                      className="btn btn-outline-secondary cart-borderplus"
                                      type="button"
                                      onClick={() =>
                                        handleUpdateQuantity(
                                          item.cart_item_id,
                                          item.quantity + 1,
                                          item.selected_options.size
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                  </div>

                                  {/* Show confirm button if quantity change is triggered */}
                                  {showConfirmButton[item.cart_item_id] &&
                                    !loadingUpdate[item.cart_item_id] && (
                                      <button
                                        className="btn btn-outline-success ml-2"
                                        type="button"
                                        onClick={() =>
                                          handleConfirmUpdate(
                                            item.cart_item_id,
                                            item.quantity
                                          )
                                        }
                                      >
                                        Update
                                      </button>
                                    )}

                                  {/* Show loading spinner if updating */}
                                  {loadingUpdate[item.cart_item_id] && (
                                    <span
                                      className="spinner-border spinner-border-sm ml-2"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  )}
                                </div>
                                <div className="cart_item_mobile_select">
                                  <label className="checkbox style-h">
                                    <input
                                      type="checkbox"
                                      checked={selectedItems.includes(
                                        item.cart_item_id
                                      )}
                                      onChange={() =>
                                        handleSelectItem(item.cart_item_id)
                                      }
                                    />
                                    <div className="checkbox__checkmark"></div>
                                    <div className="checkbox__body">
                                      <span>Select to Order</span>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>Your cart is empty.</div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="cart_page_button border-top d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={handleOrderClick}
                    >
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Cart;
