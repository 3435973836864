import React, { useEffect } from "react";

const Returnpolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <div>
      <div className="container mt-5 mb-5">
        <h1 className="mb-4 text-center">Returns and Exchange Policy</h1>
        <p>
          We want you to love what you ordered, but if something isn’t right,
          please let us know.
        </p>
        <p>
          Please read our policy carefully before making a purchase. Contact us
          at{" "}
          <a href="mailto:infi.boutique1205@gmail.com">
            infi.boutique1205@gmail.com
          </a>{" "}
          within 48 hours of receiving the package.
        </p>

        <h5>No Returns or Exchanges for Sizing Issues</h5>
        <p>
          As a made-to-order brand, we do not accept returns or exchanges for
          sizing issues. We make each outfit exactly as per the order details
          provided by the customer. Therefore, we request you to recheck your
          size using our size chart before placing your order.
        </p>

        <h5>Damaged Products</h5>
        <p>
          We accept exchanges, returns, or refunds only in cases where the
          product is damaged upon receipt.
        </p>
        <p>To claim an exchange, return, or refund for a damaged product:</p>
        <ul>
          <li>
            Contact us at{" "}
            <a href="mailto:infi.boutique1205@gmail.com">
              infi.boutique1205@gmail.com
            </a>{" "}
            within 48 hours of receiving the package.
          </li>
          <li>
            Provide an unpacking video as proof of the damage. This video is
            mandatory for processing your claim.
          </li>
          <li>
            Upon verification, we will consider your request for an exchange,
            return, or refund within 48 hours of the parcel’s delivery.
          </li>
        </ul>

        <h5>Refunds</h5>
        <p>
          Refunds will be issued in the form of a credit note equivalent to the
          amount of the outfit. The credit note will be valid for 3 months and
          can be used for future purchases.
        </p>

        <h5>Order Cancellations</h5>
        <p>
          If you wish to cancel your order, please email us at{" "}
          <a href="mailto:infi.boutique1205@gmail.com">
            infi.boutique1205@gmail.com
          </a>{" "}
          within 48 hours of placing the order.
        </p>

        <p>We appreciate your understanding and cooperation.</p>
      </div>
    </div>
  );
};

export default Returnpolicy;
